export type ErrorResponse = {
  status?: number;
  code?: string;
  message?: string;
};

export function fetchWithTimeout(
  url: RequestInfo,
  options: RequestInit | undefined = undefined,
  timeout: number = 20000
): Promise<Response> {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((_, reject) => {
      setTimeout(() => reject(new Error("timeout")), timeout);
    }),
  ]);
}
