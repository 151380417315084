import Lottie, { AnimationItem } from "lottie-web";
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import buttonAnimationIdleArrows from "../../../lotties/btn_games_interaction_arrows_0_39.json";
import "./RedCircleButtonArrows.css";
import { isMobile, isTablet } from "../../Mobile/MobileMediaDetector";
import RedCircleButton, { RedCircleButtonProps } from "./RedCircleButton";

export type RedCircleButtonArrowsProps = {
  className?: string,
  buttonProps?: RedCircleButtonProps,
};

function RedCircleButtonArrows({
  className = undefined,
  buttonProps = undefined
}: RedCircleButtonArrowsProps) {
  let animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    animation.current?.destroy();
    animation.current = Lottie.loadAnimation({
      container: document.getElementById(
        "red-circle-button-animation-arrows-" + className
      ) as Element,
      animationData: buttonAnimationIdleArrows,
      autoplay: true,
      loop: true,
      renderer: "svg",
    });
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  return (
    <div id="red-circle-button-arrows" className={className}>
      <div
        className="red-circle-button-animation-container"
        id={"red-circle-button-animation-arrows-" + className}
      ></div>
      <RedCircleButton {...buttonProps} />
    </div>
  );
}

export default RedCircleButtonArrows;
