import React from "react";
import RedCircleButton from "../../Buttons/RedCircleButton/RedCircleButton";
import { TrackGameProps } from "../TrackGameProps";
import { useTranslation } from "react-i18next";
import "./TrackBoost.css";

function TrackBoost({ onComplete }: TrackGameProps) {

  const { t } = useTranslation();

  const completeGame = () => {
    onComplete(0);
  };
  return (
    <div id="track-boost" className="full-page">
      <RedCircleButton className="boost-button" clicked={completeGame} textBelow={t("boost.red-circle")} />
    </div>
  );
}

export default TrackBoost;
