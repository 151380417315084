import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../Dialog/Dialog";
import { ReactComponent as Checkmark } from "../../../icons/checkmark.svg";
import { ReactComponent as Cross } from "../../../icons/rect_cross.svg";
import "./TunnelExplanationDialog.css";

type Props = {
  index: number;
  passedModes: boolean[];
  setShowExplanation: (show: boolean) => void;
};

const imageSources = [
  "tunnel_collect.jpg",
  "tunnel_slalom.jpg",
  "tunnel_psych.jpg",
  "tunnel_route.jpg",
];

export default function TunnelExplanationDialog({
  index,
  passedModes,
  setShowExplanation,
}: Props) {
  const { t } = useTranslation();

  const images = [];

  for (let i = 0; i < imageSources.length; i++) {
    const passed = passedModes[i];
    const source = imageSources[i];
    if (i < index) {
      images.push(
        <div id="tunnel-explanation-first">
          <img
            id="tunnel-explanation-image"
            src={require("../../../images/tunnel/" + source).default}
          />
          <div id="tunnel-explanation-checkmark">
            {passed ? (
              <Checkmark style={{ width: "100%", height: "100%" }} />
            ) : (
              <Cross
                style={{
                  width: "76%",
                  height: "76%",
                  marginLeft: "10%",
                  marginBottom: "10%",
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      // not yet played
      images.push(
        <img
          id="tunnel-explanation-image"
          src={require("../../../images/tunnel/" + source).default}
        />
      );
    }
  }

  return (
    <Dialog
      header={
        passedModes[index - 1]
          ? t("tunnel.explanation.passed")
          : t("tunnel.explanation.skipped")
      }
      title={t("tunnel.explanation.title")}
      message={t("tunnel.explanation.message")}
      className="tunnel-images"
      children={<div id="tunnel-explanation-images">{images}</div>}
      buttonProps={{
        onClick: () => setShowExplanation(false),
        text: "OK",
      }}
    />
  );
}
