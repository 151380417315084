import { Howl } from "howler";
import { useEffect, useRef } from "react";
import useAudio, { UseAudioOptions } from "./Audio";

export type AudioProps = {
  audioFile: string;
  loop?: boolean;
  onAudioEnded?: () => void;
  onAudioStarted?: () => void;
  onAudioInitialized?: (audio: UseAudioOptions) => void;
  onAudioPaused?: () => void;
};

export default function AudioPlayer({
  audioFile,
  loop = false,
  onAudioEnded,
  onAudioStarted,
  onAudioInitialized,
  onAudioPaused = undefined,
}: AudioProps) {
  const audio = useAudio(audioFile, {
    loop: loop,
    onpause: onAudioPaused,
    onplay: onAudioStarted,
    onend: onAudioEnded,
  });
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && audio.audio !== undefined) {
      onAudioInitialized?.(audio);
      initialized.current = true;
    }
    return () => {
      audio.audio?.fade(audio.audio.volume(), 0, 200);
      audio.stop();
    };
  }, [audio.audio]);

  return <div key={audioFile}></div>;
}
