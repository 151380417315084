import { Game } from "../components/Model/Challenge";
import { environment } from "../environment";
import { fetchWithTimeout } from "../Networking";

export enum PerformanceScore {
  BEST = 0,
  MEDIOCRE = 1,
  BAD = 2,
}

export enum PrivacyConsent {
  RACE_EXPERIENCE = 0,
  MARKETING = 1,
}

export class WebExpResult {
  score: number;
  time: number;
  penalties: number[];
  email: string;
  location: string;
  privacyConsent: PrivacyConsent[];
  constructor(
    score: PerformanceScore,
    time: number,
    penalties: Map<Game, number>,
    email: string,
    location: string,
    privacyConsent: PrivacyConsent[]
  ) {
    this.score = score;
    this.time = time;
    this.penalties = [];
    penalties.forEach((value) => {
      this.penalties.push(value);
    });
    this.email = email;
    this.location = location;
    this.privacyConsent = privacyConsent;
  }
}

function getToken(): Promise<Response> {
  const url =
    environment.baseUrl + "/oauth/token?grant_type=client_credentials";
  const fetchOptions = {
    method: "POST",
    headers: {
      Authorization:
        "Basic " + btoa("609e438055846a532c03ca80:fh286fbXiz1m8G4qP0"),
    },
  };
  return fetchWithTimeout(url, fetchOptions);
}

export function submitWebExpResult(
  webExpResult: WebExpResult
): Promise<Response> {
  const url = environment.baseUrl + "/api/web-exp/submit";
  var fetchOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    },
    body: JSON.stringify(webExpResult),
  };

  return getToken()
    .then((data) => data.json())
    .then((data) => {
      fetchOptions.headers.Authorization = "Bearer " + data.access_token;
      return fetchWithTimeout(url, fetchOptions);
    });
}

export type RedeemBoosts = {
  code: string;
  raceLicenseId: string;
};

export function redeemBoosts(redeemBoosts: RedeemBoosts): Promise<Response> {
  const url =
    environment.baseUrl +
    "/api/web-exp/redeem?code=" +
    redeemBoosts.code +
    "&raceLicenseId=" +
    redeemBoosts.raceLicenseId;
  var fetchOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    },
  };

  return getToken()
    .then((data) => data.json())
    .then((data) => {
      fetchOptions.headers.Authorization = "Bearer " + data.access_token;

      return fetchWithTimeout(url, fetchOptions);
    });
}
