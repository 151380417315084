import Lottie, { AnimationItem } from "lottie-web";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fadeIn, fadeOut } from "../../../Animations";
import useAudio from "../../../audio/Audio";
import { ReactComponent as Checkmark } from "../../../icons/checkmark.svg";
import { ReactComponent as Cross } from "../../../icons/cross.svg";
import { isMobile, isTablet } from "../../Mobile/MobileMediaDetector";
import { TrackGameProps } from "../TrackGameProps";
import TunnelMode from "./Mode/TunnelMode";
import "./TrackTunnel.css";
import TunnelExplanationDialog from "./TunnelExplanationDialog";

export enum TunnelModeState {
  COLLECT,
  SLALOM,
  ROUTE,
  PSYCH,
}

function TrackTunnel({ onComplete }: TrackGameProps) {
  let tunnelMode = useRef<any>();

  const steps = [
    TunnelModeState.COLLECT,
    TunnelModeState.SLALOM,
    TunnelModeState.PSYCH,
    TunnelModeState.ROUTE,
  ];
  const [tunnelModeIndex, setTunnelModeIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [penalty, setPenalty] = useState(0);
  const [showInfo1, setShowInfo1] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const [buttonActivated, setButtonActivated] = useState(false);
  const isAnimating = useRef<boolean>(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [finished, setFinished] = useState(false);
  const passed = useRef([true, true, true, true]); // set to false when skipped
  let animation = useRef<AnimationItem | null>(null);

  const tunnelSprite = useAudio("tunnel/tunnelgames_audiosprite", {
    sprite: {
      completed: [0, 2472],
      coin_bad: [4000, 960],
      coin_good: [6000, 960],
      slalom_bad: [8000, 528],
      slalom_good: [10000, 480],
      psych_boostup: [12000, 1560],
      route_off: [15000, 480],
    },
  });

  const { t } = useTranslation();

  const nextIteration = useCallback(
    (completed: boolean = true) => {
      setButtonActivated(false);
      if (!isAnimating.current) {
        const stepIndex = tunnelModeIndex + 1;
        if (stepIndex < steps.length) {
          isAnimating.current = true;
          fadeOut("#tunnel-mode-animation", 1000);
          tunnelMode.current?.animate(completed, () => {
            fadeIn("#tunnel-mode-animation");
            setProgress(0); // reset progress
            setShowInfo1(false);
            setShowInfo2(false);
            setShowExplanation(true);
            setTunnelModeIndex(stepIndex); // move to next mode
            isAnimating.current = false;
          });
        } else {
          setFinished(true);
          setShowExplanation(true);
        }
      }
    },
    [tunnelModeIndex]
  );

  useEffect(() => {
    setupAnimation(steps[tunnelModeIndex]);

    return () => {
      animation.current?.removeEventListener("complete");
      animation.current?.removeEventListener("enterFrame");
      animation.current?.destroy();
      animation.current = null;
    };
  }, [tunnelModeIndex]);

  useEffect(() => {
    animation.current?.removeEventListener("enterFrame");
    animation.current?.addEventListener("enterFrame", onProgress);
  }, [tunnelSprite.audio]);

  /// Get the animation data for the provided TunnelModeState
  function getAnimationData(state: TunnelModeState): Promise<any> {
    switch (state) {
      case TunnelModeState.COLLECT:
        return import("../../../lotties/040_tunnel_collect_0_99.json");
      case TunnelModeState.SLALOM:
        return import("../../../lotties/040_tunnel_slalom_0_99.json");
      case TunnelModeState.ROUTE:
        return import("../../../lotties/040_tunnel_route_0_99.json");
      case TunnelModeState.PSYCH:
        return import("../../../lotties/040_tunnel_psych_0_99.json");
    }
  }

  function getInfo1(state: TunnelModeState): string {
    switch (state) {
      case TunnelModeState.COLLECT:
        return "tunnel.collect.info1";
      case TunnelModeState.SLALOM:
        return "tunnel.slalom.info1";
      case TunnelModeState.ROUTE:
        return "tunnel.route.info1";
      case TunnelModeState.PSYCH:
        return "tunnel.psych.info1";
    }
  }

  function getInfo1Icon(state: TunnelModeState): any {
    switch (state) {
      case TunnelModeState.COLLECT:
        return <Cross className="icon-cross" />;
      case TunnelModeState.SLALOM:
        return <Checkmark className="icon-checkmark" />;
      case TunnelModeState.ROUTE:
        return <Checkmark className="icon-checkmark" />;
      case TunnelModeState.PSYCH:
        return <Cross className="icon-cross" />;
    }
  }

  function getInfo2(state: TunnelModeState): string {
    switch (state) {
      case TunnelModeState.COLLECT:
        return "tunnel.collect.info2";
      case TunnelModeState.SLALOM:
        return "tunnel.slalom.info2";
      case TunnelModeState.ROUTE:
        return "tunnel.route.info2";
      case TunnelModeState.PSYCH:
        return "tunnel.psych.info2";
    }
  }

  function getInfo2Icon(state: TunnelModeState): any {
    switch (state) {
      case TunnelModeState.COLLECT:
        return <Checkmark className="icon-checkmark" />;
      case TunnelModeState.SLALOM:
        return <Cross className="icon-cross" />;
      case TunnelModeState.ROUTE:
        return <Cross className="icon-cross" />;
      case TunnelModeState.PSYCH:
        return <Checkmark className="icon-checkmark" />;
    }
  }

  const onProgress = () => {
    const frames = (animation.current?.totalFrames ?? 99) - 1;
    const currentFrame = animation.current?.currentFrame ?? 0;
    const progressPercent = Math.ceil((currentFrame / frames) * 100.0);
    setProgress(progressPercent);

    // show relevant elements (can be based on current mode / state)
    if (progressPercent > 20 && !showInfo1) {
      setShowInfo1(true);
    }
    if (progressPercent > 60 && !showInfo2) {
      setShowInfo2(true);
    }

    const mode = steps[tunnelModeIndex];

    if (!tunnelSprite.audio?.playing()) {
      if (mode === TunnelModeState.COLLECT) {
        if (progressPercent < 50) {
          tunnelSprite.audio?.play("coin_bad");
        } else if (progressPercent >= 50 && progressPercent < 90) {
          tunnelSprite.audio?.play("coin_good");
        }
      } else if (mode === TunnelModeState.SLALOM) {
        if (
          (progressPercent > 3 && progressPercent < 15) ||
          (progressPercent > 30 && progressPercent < 40) ||
          (progressPercent >= 80 && progressPercent < 90)
        ) {
          tunnelSprite.audio?.play("slalom_good");
        } else if (progressPercent >= 60 && progressPercent < 70) {
          tunnelSprite.audio?.play("slalom_bad");
        }
      } else if (mode === TunnelModeState.PSYCH) {
        if (progressPercent > 65 && progressPercent < 70) {
          tunnelSprite.audio?.play("psych_boostup");
        }
      } else if (mode === TunnelModeState.ROUTE) {
        if (progressPercent > 50 && progressPercent < 60) {
          tunnelSprite.audio?.play("route_off");
        }
      }
    }

    if (progressPercent > 98) {
      tunnelSprite.audio?.play("completed");
    }
  };

  function setupAnimation(state: TunnelModeState) {
    animation.current?.destroy();
    getAnimationData(state).then((data) => {
      animation.current = Lottie.loadAnimation({
        container: document.getElementById("tunnel-mode-animation") as Element,
        animationData: data,
        autoplay: false,
        loop: false,
        renderer: "svg",
      });
      animation.current?.addEventListener("complete", nextIteration);
      animation.current?.addEventListener("enterFrame", onProgress);
    });
  }

  /// starting slowly increases speed for an ease in effect
  const start = () => {
    setButtonActivated(true);
    animation.current?.setSpeed(0.1);
    animation.current?.play();
    for (let i = 1; i <= 10; i++) {
      setTimeout(() => {
        animation.current?.setSpeed(i * 0.1);
      }, i * 25);
    }
  };

  /// starting slowly decreases speed for an ease out effect
  const stop = () => {
    setButtonActivated(false);
    for (let i = 0; i <= 10; i++) {
      setTimeout(() => {
        animation.current?.setSpeed((10 - i) * 0.1);
        if (i === 10) {
          animation.current?.pause();
        }
      }, i * 25);
    }
  };

  return (
    <div id="tunnel" className="full-page">
      <div
        key="tunnel-mode-animation"
        id="tunnel-mode-animation"
        className="full-page full-size"
      ></div>
      <div
        id="tunnel-learn-button"
        onClick={() => {
          if (buttonActivated) {
            stop();
          } else {
            start();
          }
        }}
      >
        <p>
          {buttonActivated
            ? t("tunnel.red-circle-stop")
            : t("tunnel.red-circle-start")}
        </p>
      </div>
      <div
        id="skip-tunnel"
        className="button"
        onClick={() => {
          // one second penalty per skip
          setPenalty(penalty + 1);
          nextIteration(false);
          passed.current[tunnelModeIndex] = false;
        }}
      >
        <h3>{t("games.skip")}</h3>
      </div>
      <div className={"tunnel-info " + (showInfo1 ? "info-1-visible" : "")}>
        {getInfo1Icon(steps[tunnelModeIndex])}
        {t(getInfo1(steps[tunnelModeIndex]))}
      </div>
      <div className={"tunnel-info " + (showInfo2 ? "info-2-visible" : "")}>
        {getInfo2Icon(steps[tunnelModeIndex])}
        {t(getInfo2(steps[tunnelModeIndex]))}
      </div>
      <TunnelMode
        ref={tunnelMode}
        mode={steps[tunnelModeIndex]}
        nextMode={steps[tunnelModeIndex + 1]}
        progress={progress}
      />
      {showExplanation && (
        <TunnelExplanationDialog
          setShowExplanation={() => {
            if (finished) {
              onComplete(penalty);
            } else {
              start()
            }
            setShowExplanation(false);
          }}
          index={finished ? 4 : tunnelModeIndex}
          passedModes={passed.current}
        />
      )}
    </div>
  );
}

export default TrackTunnel;
