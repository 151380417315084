import Lottie, { AnimationItem } from "lottie-web";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import UAParser from "ua-parser-js";
import { fadeIn, fadeOut } from "../../Animations";
import AudioContext from "../../Context/AudioContext";
import { requestFullScreen } from "../../Helper";
import introLottie from "../../lotties/000_intro_part1_0_135_part2_136_172_toplayer_only.json";
import AudioButton from "../Buttons/AudioButton/AudioButton";
import RhombusButton from "../Buttons/RhombusButton";
import { uaParserInstance } from "../Mobile/MobileMediaDetector";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./PreIntro.css";

export enum LoadingState {
  Start,
  Loading,
  Finished,
}

function PostIntro() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const animation = useRef<AnimationItem | null>(null);
  const { t, i18n } = useTranslation();
  const [videoState, setVideoState] = useState(VideoState.Ended);
  const [loadingState, setLoadingState] = useState(LoadingState.Start);
  const history = useHistory();
  const audioContext = useContext(AudioContext);

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementById("intro-animation2") as Element,
        animationData: introLottie,
        autoplay: true,
        renderer: "svg",
        initialSegment: [135, 136],
        loop: false,
      });
    }
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // start loading animation
      setLoadingState(LoadingState.Loading);
      fadeIn("#progress-container");
      setTimeout(() => {
        setLoadingState(LoadingState.Finished);
        fadeOut("#progress", 500, "visible");
      }, 1750); // this needs to match the transition time in .css (or slightly more)
    }, 500);
  }, []);

  const imageSrc = "000_intro_part1.last";
  const videoProps: VideoProps = {
    src: i18n.language + "/000_intro_part2",
    muted: !audioContext.audioEnabled,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      history.push("/home");
    },
  };

  const device = uaParserInstance.getDevice();

  return (
    <div id="intro">
      <div id="intro-content">
        <VideoTransition
          videoState={videoState}
          image={imageSrc}
          videoProps={videoProps}
          ref={videoRef}
        >
          <div
            key="intro-animation2"
            id="intro-animation2"
            className="full-page full-size"
          ></div>
        </VideoTransition>
        <div
          id="intro-container"
          className="full-page flex-column align-center"
        >
          <h1>{t("landing.headline")}</h1>
          <div className="flex-column align-center">
            <RhombusButton
              disabled={loadingState !== LoadingState.Finished}
              className={
                loadingState === LoadingState.Finished
                  ? "opacity-100"
                  : "opacity-0"
              }
              onClick={() => {
                if (
                  (navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document) ||
                  device.type === UAParser.DEVICE.TABLET
                ) {
                  requestFullScreen();
                }
                fadeOut("#intro-container");
                if (videoRef.current) {
                  // hack needed to get sound on video on mobile
                  videoRef.current.muted = !audioContext.audioEnabled;
                  videoRef.current.play();
                }
                setVideoState(VideoState.Play);
                animation.current?.playSegments([136, 172]);
              }}
              text={t("landing.start")}
            />
            <div id="progress-container" className="opacity-0">
              <div id="progress">
                <div
                  className={
                    loadingState === LoadingState.Start
                      ? "progress-0"
                      : "progress-100"
                  }
                ></div>
              </div>
              <p id="progress-text">
                {loadingState === LoadingState.Finished
                  ? t("landing.experience") + t("landing.sound")
                  : t("landing.loading")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {videoState === VideoState.Play && <AudioButton />}
    </div>
  );
}

export default PostIntro;
