import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "../../../CircularProgress/CircularProgress";
import MobileDetector from "../../../Mobile/MobileDetector/MobileDetector";
import LapTimeCalculator from "../../../Model/LapTimeCalculator";
import LapTime from "../../../SceneOverlay/OverlayElements/LapTime";
import { useTranslation } from "react-i18next";
import "./FinishResult.css";
import AnimatedParagraph from "../../../AnimatedParagraph/AnimatedParagraph";
import { PerformanceScore } from "../../../../api/WebExpService";
import { ReactComponent as Medal } from "../../../../icons/ic_medal.svg";

export type FinishResultProps = {
  lapTimeCalculator: LapTimeCalculator;
};

function performanceTitle(performanceScore: PerformanceScore): string {
  switch (performanceScore) {
    case PerformanceScore.BEST:
      return "finish.best.title";
    case PerformanceScore.MEDIOCRE:
      return "finish.mediocre.title";
    case PerformanceScore.BAD:
      return "finish.bad.title";
  }
}

function FinishResult({ lapTimeCalculator }: FinishResultProps) {
  const { t } = useTranslation();

  const circleProps = [
    {
      percent: Math.round(lapTimeCalculator.getReaction()),
      text: t("finish.reaction"),
    },
    {
      percent: Math.round(lapTimeCalculator.getCorners()),
      text: t("finish.corners"),
    },
    {
      percent: Math.round(lapTimeCalculator.getTunnel()),
      text: t("finish.tunnel"),
    },
    {
      percent: Math.round(lapTimeCalculator.getBreaking()),
      text: t("finish.braking"),
    },
  ];

  const circleProgress = [];
  for (let prop of circleProps) {
    circleProgress.push(
      <CircularProgress key={prop.text + "circle"} {...prop} />
    );
  }

  const mobileProgress = [];
  for (let props of circleProps) {
    mobileProgress.push(
      <div
        key={props.text}
        id="mobile-progress-indicator"
        className="flex-column align-center justify-center"
      >
        <h5>{props.percent + " %"}</h5>
        <p>{props.text}</p>
      </div>
    );
  }

  return (
    <div id="finish-result-content">
      <div id="medal-wrapper" className="flex justify-center align-center">
        <Medal />
        <AnimatedParagraph
          wrapper="h4"
          id="finish-result-content-title"
          text={t(performanceTitle(lapTimeCalculator.getPerformanceScore()))}
        />
      </div>
      <MobileDetector
        mobileFallback={
          <div id="mobile-progress-container" className="flex">
            {mobileProgress}
          </div>
        }
      >
        <div className="flex align-center justify-center">
          <LapTime staticLapTime={lapTimeCalculator.nextLapTime} />
          <div style={{ width: "24px" }} />
          {circleProgress}
        </div>
      </MobileDetector>
    </div>
  );
}

export default FinishResult;
