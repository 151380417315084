import React from "react";
import {
  getImageFileExtension,
  getImageType,
  SupportedImageFormat,
} from "../FileFormats/SupportedFormat";
import { isMobile, uaParserInstance } from "../Mobile/MobileMediaDetector";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    importance?: string;
  }
}

export type ImageProps = {
  id?: string;
  className?: string;
  src: string;
  pictureProps?: React.HTMLAttributes<HTMLPictureElement>;
  style?: any;
};

const mobile = isMobile();

function MultiSourceImage({
  id = undefined,
  className = undefined,
  src,
  pictureProps = undefined,
  style = undefined,
}: ImageProps) {
  const sources = [];
  const browser = uaParserInstance.getBrowser();
  const imageFormats =
    browser.name === "Edge"
      ? [SupportedImageFormat.PNG]
      : [
          SupportedImageFormat.AVIF,
          SupportedImageFormat.WEBP,
          SupportedImageFormat.PNG,
        ];

  // add sources for all supported formats
  for (let format of imageFormats) {
    const fileExtension = getImageFileExtension(format);
    const type = getImageType(format);

    if (mobile) {
      try {
        const image = require("../../images/mobile/" +
          src +
          fileExtension).default;
        sources.push(
          <source
            key={type + format + "mobile"}
            media="(max-width: 992px)"
            type={type}
            srcSet={image}
          />
        );
      } catch {
        console.log("Mobile file not found " + src);
      }
    }

    try {
      const image = require("../../images/" + src + fileExtension).default;
      sources.push(<source key={type + format} type={type} srcSet={image} />);
    } catch {
      console.log("File not found " + src);
    }
  }

  // <img> tag required
  // no source, otherwise some browser load two images
  sources.push(
    <img
      draggable={false}
      key={src + "fallback"}
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
      alt=""
      importance="high"
      loading="eager"
    />
  );

  return (
    <picture id={id} className={className} style={style} {...pictureProps}>
      {sources}
    </picture>
  );
}

export default MultiSourceImage;
