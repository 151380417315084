import React from "react";
import { useTranslation } from "react-i18next";
import "./CircularProgress.css";

export type CircularProgressProps = {
  percent: number;
  text: string;
};

function CircularProgress({ percent, text }: CircularProgressProps) {
  const { t } = useTranslation();

  const roundedPercent = Math.round(percent);

  const radius = 25;
  const size = radius * 2 + 4;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (roundedPercent / 100) * circumference;

  return (
    <div id="progress-content">
      <svg className="progress-ring" width={size} height={size}>
        <circle
          className="progress-ring__circle"
          stroke="gray"
          strokeWidth="2"
          fill="none"
          r={radius}
          cx="50%"
          cy="50%"
        />
        <circle
          className="progress-ring__circle"
          stroke="#ece200"
          strokeWidth="2"
          fill="none"
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={offset}
          r={radius}
          cx="50%"
          cy="50%"
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          fill="white"
          fontSize="13px"
          fontFamily="TradeGothic"
          dy=".3em"
        >
          {roundedPercent + " %"}
        </text>
      </svg>
      <p>{t(text)}</p>
    </div>
  );
}

export default CircularProgress;
