import React, { useEffect } from "react";
import { ButtonProps } from "../Buttons/ButtonProps";
import RhombusButton from "../Buttons/RhombusButton";
import ModalView from "../ModalView/ModalView";
import "./Dialog.css";

export type DialogProps = {
  header?: string; // the top text displayed in red
  title?: string;
  subTitle?: string;
  message?: string;
  buttonProps?: ButtonProps;
  retryButtonProps?: ButtonProps;
  children?: React.ReactNode;
  className?: string;
};

function Dialog({
  header = undefined,
  title = undefined,
  subTitle = undefined,
  message = undefined,
  buttonProps = undefined,
  retryButtonProps = undefined,
  children = undefined,
  className = undefined,
}: DialogProps) {
  const key = (event: KeyboardEvent) => {
    if (event.key === "Escape" || event.key === "Enter") {
      buttonProps?.onClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", key);
    return () => {
      document.removeEventListener("keydown", key);
    };
  }, []);

  return (
    <ModalView showing={true} className="flex align-center justify-center">
      <div
        id="dialog-popover"
        className={
          "flex-column align-center justify-center" +
          (className ? " " + className : "")
        }
      >
        {header && <h4>{header}</h4>}
        {title && <h1>{title}</h1>}
        {subTitle && <h2>{subTitle}</h2>}
        {message && <p>{message}</p>}
        {children}
        {(buttonProps || retryButtonProps) && (
          <div className="flex">
            {retryButtonProps && (
              <RhombusButton
                className="dialog-button plain"
                {...retryButtonProps}
              />
            )}
            {buttonProps && (
              <RhombusButton className="dialog-button" {...buttonProps} />
            )}
          </div>
        )}
      </div>
    </ModalView>
  );
}

export default Dialog;
