export type TestimonialLesson = {
  number: number;
  name: string;
  headline?: string;
  items: Array<string>;
  images?: Array<string>;
};

export type TestimonialSpeech = {
  text: string;
  audioFile?: string;
};

export default class TestimonialModel {
  readonly infoBeforeGame?: TestimonialSpeech;
  readonly infoDuringGame?: TestimonialSpeech;
  readonly infoAfterGame?: TestimonialSpeech;
  readonly lesson?: TestimonialLesson;

  constructor(
    infoBeforeGame?: TestimonialSpeech,
    infoDuringGame?: TestimonialSpeech,
    infoAfterGame?: TestimonialSpeech,
    lesson?: TestimonialLesson
  ) {
    this.infoBeforeGame = infoBeforeGame;
    this.infoDuringGame = infoDuringGame;
    this.infoAfterGame = infoAfterGame;
    this.lesson = lesson;
  }
}
