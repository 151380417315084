import { Howl } from "howler";
import React, { RefObject, useState } from "react";
import { fadeOut } from "../../Animations";
import { UseAudioOptions } from "../../audio/Audio";
import { AudioProps } from "../../audio/AudioPlayer";
import RhombusButton from "../Buttons/RhombusButton";
import { ChallengeInfo, Game, GameState } from "../Model/Challenge";
import SceneOverlay from "../SceneOverlay/SceneOverlay";
import Testimonial, { TestimonialState } from "../Testimonial/Testimonial";
import TestimonialOverlay from "../Testimonial/TestimonialOverlay";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./Scene.css";

export type SceneHandler = {
  challenge: ChallengeInfo;
  gameState: GameState;
  videoState: VideoState;
  videoProps: VideoProps;
  lapTime: number;
  nextLapTime: number;
  gameElement: any;
  onAudioInitialized: (audio: UseAudioOptions) => void;
  onAudioEnded?: () => void;
  mainButtonText: string;
  mainButtonClick: () => void;
  numberOfTries: number;
  videoRef?: RefObject<HTMLVideoElement>;
};

function Scene({
  challenge,
  gameState,
  gameElement,
  videoState,
  videoProps,
  lapTime,
  nextLapTime,
  onAudioInitialized,
  onAudioEnded = undefined,
  mainButtonText,
  mainButtonClick,
  numberOfTries,
  videoRef = undefined,
}: SceneHandler) {
  const [showTestimonialOverlay, setShowTestimonialOverlay] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(true);

  const lesson = challenge.testimonialModel.lesson;
  const testimonialState = challenge.testimonialStateForGameState(gameState);

  const onTestimonialClick: (() => void) | undefined =
    lesson !== undefined && testimonialState === TestimonialState.MORE_INFO
      ? () => {
          setShowTestimonialOverlay(!showTestimonialOverlay);
        }
      : undefined;

  const onTestimonialClose = () => {
    setShowFullDescription(false);
  };

  const infoBeforeGame = challenge.testimonialModel.infoBeforeGame;
  const infoDuringGame = challenge.testimonialModel.infoDuringGame;
  const infoAfterGame = challenge.testimonialModel.infoAfterGame;

  let audioProps: AudioProps | undefined = undefined;
  let testimonialText: string | undefined = undefined;

  if (gameState === GameState.BEFORE_GAME && infoBeforeGame) {
    testimonialText = infoBeforeGame.text;
    if (infoBeforeGame.audioFile) {
      audioProps = {
        audioFile: infoBeforeGame.audioFile,
        onAudioInitialized: onAudioInitialized,
        onAudioEnded: onAudioEnded,
      };
    }
  } else if (gameState === GameState.DURING_GAME && infoDuringGame) {
    testimonialText = infoDuringGame.text;
    if (infoDuringGame.audioFile) {
      audioProps = {
        audioFile: infoDuringGame.audioFile,
        onAudioInitialized: onAudioInitialized,
        onAudioEnded: onAudioEnded,
      };
    }
  } else if (gameState === GameState.AFTER_GAME && infoAfterGame) {
    testimonialText = infoAfterGame.text;
    if (infoAfterGame.audioFile) {
      audioProps = {
        audioFile: infoAfterGame.audioFile,
        onAudioInitialized: onAudioInitialized,
        onAudioEnded: onAudioEnded,
      };
    }
  }

  // hide during all games, and even after the game for boost and tunnel challenge
  const hideMainButton =
    gameState === GameState.DURING_GAME ||
    (gameState === GameState.AFTER_GAME &&
      (challenge.gameType === Game.BOOST ||
        challenge.gameType === Game.TUNNEL));

  const showGame =
    (gameState === GameState.DURING_GAME ||
      challenge.gameType === Game.LINE1 ||
      challenge.gameType === Game.LINE2 ||
      challenge.gameType === Game.BREAK) &&
    videoState === VideoState.Ended;

  const closeTestimonialOverlay = () => {
    if (showTestimonialOverlay === true) {
      setShowTestimonialOverlay(false);
    }
  };

  return (
    <div id="scene" className="full-page" onClick={closeTestimonialOverlay}>
      <VideoTransition
        ref={videoRef}
        videoState={videoState}
        image={challenge.imageSrc}
        videoProps={videoProps}
      >
        {showGame && gameElement}
      </VideoTransition>
      <SceneOverlay
        videoState={videoState}
        game={challenge.gameType}
        onNavigate={() => {
          fadeOut("#scene-overlay");
          fadeOut("#scene-testimonial");
          fadeOut("#scene-main-button");
        }}
        currentLapTime={lapTime}
        nextLapTime={nextLapTime}
        challengeDescription={{
          number: challenge.id,
          text: challenge.name,
          gameState: gameState,
          showFullText: showFullDescription,
        }}
      />
      <div id="scene-main-button" className={hideMainButton ? "gone" : ""}>
        <RhombusButton
          className=""
          text={mainButtonText}
          onClick={mainButtonClick}
        />
      </div>
      {lesson !== undefined && (
        <TestimonialOverlay
          lesson={lesson}
          isOpen={showTestimonialOverlay}
          onClick={closeTestimonialOverlay}
        />
      )}
      <div
        id="scene-testimonial"
        className={
          "zindex-2" +
          (showTestimonialOverlay
            ? " scene-testimonial-open"
            : " scene-testimonial-closed")
        }
      >
        <Testimonial
          key={audioProps?.audioFile ?? "testimonial" + testimonialState}
          onClick={onTestimonialClick}
          onClose={onTestimonialClose}
          challenge={challenge}
          state={
            videoState !== VideoState.Ended
              ? TestimonialState.INACTIVE
              : testimonialState
          }
          open={showTestimonialOverlay}
          audioProps={audioProps}
          text={testimonialText}
          numberOfTries={numberOfTries}
        />
      </div>
    </div>
  );
}

export default Scene;
