import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Checkmark } from "../../../../icons/checkmark.svg";
import { TunnelModeState } from "../TrackTunnel";
import "./TunnelMode.css";

export type TunnelModeProps = {
  mode: TunnelModeState;
  nextMode?: TunnelModeState;
  progress?: number;
};

const modeName = (state: TunnelModeState): string => {
  switch (state) {
    case TunnelModeState.COLLECT:
      return "tunnel.collect.name";
    case TunnelModeState.SLALOM:
      return "tunnel.slalom.name";
    case TunnelModeState.PSYCH:
      return "tunnel.psych.name";
    case TunnelModeState.ROUTE:
      return "tunnel.route.name";
  }
};

const modeNumber = (state: TunnelModeState): string => {
  switch (state) {
    case TunnelModeState.COLLECT:
      return "1";
    case TunnelModeState.SLALOM:
      return "2";
    case TunnelModeState.PSYCH:
      return "3";
    case TunnelModeState.ROUTE:
      return "4";
  }
};

const TunnelMode = React.forwardRef(
  ({ mode, nextMode, progress }: TunnelModeProps, ref) => {
    const { t } = useTranslation();

    const [currentVisible, setCurrentVisible] = useState(true);
    const [nextVisible, setNextVisible] = useState(false);
    const [transitionX, setTransitionX] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [showCheckmark, setShowCheckmark] = useState(true);

    useImperativeHandle(ref, () => ({
      animate: (completed: boolean = true, callback: () => void) => {
        setShowCheckmark(completed);
        setCompleted(true);

        setTimeout(() => {
          setNextVisible(true);
          setCurrentVisible(false);
          setTransitionX(true);

          setTimeout(() => {
            setNextVisible(false);
            setCurrentVisible(true);
            setTransitionX(false);
            setCompleted(false);

            callback();
          }, 750);
        }, 350);
      },
    }));

    return (
      <div id="tunnel-mode">
        <div
          id="tunnel-mode-container"
          className={transitionX ? "transitionX-50" : "transitionToOrigin"}
        >
          <div
            id="tunnel-text-container"
            className={currentVisible ? "opacity-100" : "fadeOut"}
          >
            <div>
              <h3>{t("tunnel.mode") + " " + modeNumber(mode)}</h3>
              <h2>{t(modeName(mode))}</h2>
            </div>
            <div
              id="tunnel-checkmark-container"
              className={completed && showCheckmark ? "fadeIn" : "opacity-0"}
            >
              <Checkmark id="tunnel-checkmark" />
            </div>
          </div>

          <div
            id="tunnel-text-container-next"
            className={nextVisible ? "fadeIn" : "opacity-0"}
          >
            <h3>{t("tunnel.mode") + " " + modeNumber(nextMode!!)}</h3>
            <h2>{t(modeName(nextMode!!))}</h2>
          </div>
        </div>
        <h1>{progress ?? 100}%</h1>
      </div>
    );
  }
);

export default TunnelMode;
