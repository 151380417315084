import React from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../Buttons/CloseButton/CloseButton";
import { TestimonialLesson } from "../Model/TestimonialModel";
import MultiSourceImage from "../MultiSourceImage/MultiSourceImage";
import "./TestimonialOverlay.css";

export type TestimonialOverlayProps = {
  lesson: TestimonialLesson;
  isOpen: boolean;
  onClick: () => void;
};

function TestimonialOverlay({
  lesson,
  isOpen = false,
  onClick,
}: TestimonialOverlayProps) {
  const { t } = useTranslation();

  const listItems = [];
  for (let item of lesson.items) {
    listItems.push(<li key={item}>{t(item)}</li>);
  }

  const images = [];
  for (let image of lesson.images ?? []) {
    images.push(<MultiSourceImage key={image} src={image} />);
  }

  return (
    <div
      id="testimonial-overlay"
      className={isOpen ? "" : " closed"}
      onClick={onClick}
    >
      <CloseButton id="testimonial-overlay-close" onClick={onClick} />
      <div id="testimonial-content">
        <div className={images.length === 0 ? "flex-75" : "flex-50"}>
          <h4>{t("overlay.lesson") + " #" + lesson.number}</h4>
          <h3>{t(lesson.name)}</h3>
          {lesson.headline !== undefined && <h5>{t(lesson.headline)}</h5>}
          <ol>{listItems}</ol>
          <div id="spacer"></div>
        </div>
        {images.length > 0 && (
          <div
            id="testimonial-overlay-images"
            className="flex-25 flex-column align-center justify-center"
          >
            {images}
          </div>
        )}
      </div>
    </div>
  );
}

export default TestimonialOverlay;
