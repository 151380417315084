// Copyright Butleroy GmbH 2020

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ViewPortHandler.css';

export const viewPortConstants = {
  maxRatioX: 2.24,
  perfectRatioX: 1.778,
  minRatioX: 1.33,
  maxRatioY: 0.75,
  perfectRatioY: 0.5625,
  minRatioY: 0.4464, // 1/2.24 = 0.4464
}

const perfectRatio = viewPortConstants.perfectRatioX // perfect 16:9 width:height ratio
const videoRenderHeight = 1260
const videoRenderWidth = 2240
const verticalDistanceToSafeArea = 200 // distance from video top / bottom to action safe area top / bottom
const horizontalDistanceToSafeArea = 320 // distance from video left / right to action safe area left / right

class ViewPortHandler extends Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, ratio: 0.0, leftRightCrop: 0, maxWidth: 0, videoWidth: 0, videoHeight: 0 };
    this.refreshDimensions = this.refreshDimensions.bind(this);
  }

  componentDidMount() {
    this.refreshDimensions();
    window.addEventListener('resize', this.refreshDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshDimensions.bind(this));
  }

  refreshDimensions() {
    const width = window.innerWidth / this.props.widthDivider;
    const height = window.innerHeight / this.props.heightDivider;

    const maxRatio = viewPortConstants.maxRatioX; // 2.24 -> horizontal extreme 2240 / (800+100+100) (if higher we show black borders)
    const minRatio = viewPortConstants.minRatioX; // 1.33 -> vertical extreme 1680 / 1260 (if higher we show black borders)
    const ratio = width / height;
    const appliedRatio = Math.min(maxRatio, Math.max(minRatio, ratio))

    var videoHeight = 0
    var videoWidth = 0
    var leftRightCrop = 0

    if (ratio < perfectRatio) { // width is limiter

      videoHeight = width / appliedRatio
      videoWidth = width
      leftRightCrop = Math.abs(videoHeight * (perfectRatio - appliedRatio))
    } else { // height is limiter

      videoHeight = height
      videoWidth = height * appliedRatio
      leftRightCrop = 0
    }

    const maxWidth = height * appliedRatio + leftRightCrop

    this.setState({ width: width, ratio: appliedRatio, leftRightCrop: leftRightCrop, maxWidth: maxWidth, videoWidth: videoWidth, videoHeight: videoHeight });
  }

  render() {
    const viewWidth = this.state.width + this.state.leftRightCrop
    const maxWidth = this.state.maxWidth

    const viewWidthPercent = viewWidth / window.innerWidth * 100 * this.props.widthDivider;
    const maxWidthPercent = maxWidth / window.innerWidth * 100 * this.props.widthDivider;
    const leftRightCropPercent = this.state.leftRightCrop / window.innerWidth * 100 * this.props.widthDivider;

    // const viewPortStyle = {
    //   position: "relative",
    //   display: "flex",
    //   top: 0,
    //   left: -(this.state.leftRightCrop / 2),
    //   bottom: 0,
    //   right: (this.state.leftRightCrop / 2),
    //   width: viewWidth,
    //   overflow: "hidden",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   clipPath: "inset(0px "+ (this.state.leftRightCrop / 2) + "px 0px " + (this.state.leftRightCrop / 2) + "px)"
    // };
    // const boxStyle = {
    //   position: "relative",
    //   maxWidth: maxWidth + "px",
    //   width: 100 + "%"
    // };

    // console.log("viewWidthPercent: " + viewWidthPercent);
    // console.log("maxWidthPercent: " + maxWidthPercent);
    // console.log("leftRightCropPercent: " + leftRightCropPercent);

    const viewPortStyle = {
      position: "fixed",
      // height: "100%",
      display: "flex",
      top: 0,
      left: -(leftRightCropPercent / 2) + "%",
      bottom: 0,
      right: (leftRightCropPercent / 2) + "%",
      width: viewWidthPercent + "%",
      // overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      clipPath: "inset(0px " + (this.state.leftRightCrop / 2) + "px 0px " + (this.state.leftRightCrop / 2) + "px)"
    };

    const boxStyle = {
      position: "relative",
      maxWidth: maxWidthPercent + "%",
      width: 100 + "%"
    };

    const visibleBoxStyle = {
      position: "fixed",
      display: "flex",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: "none"
    };

    const ratioDiff = this.state.ratio - perfectRatio
    const ratioPercentDiff = ratioDiff / perfectRatio

    const heightDiff = this.state.videoHeight * ratioPercentDiff
    const actualVideoHeight = Math.max(this.state.videoHeight + heightDiff, this.state.videoHeight)
    const uiSafeRatio = (verticalDistanceToSafeArea * 2) / videoRenderHeight
    const uiSafeHeight = actualVideoHeight * uiSafeRatio
    const safeHeight = actualVideoHeight - uiSafeHeight

    const actualVideoWidth = Math.max(this.state.videoWidth + this.state.leftRightCrop, this.state.videoWidth)
    const leftRightOverscanRatio = (horizontalDistanceToSafeArea * 2) / videoRenderWidth
    const uiSafeWidth = actualVideoWidth * leftRightOverscanRatio
    const safeWidth = actualVideoWidth - uiSafeWidth

    const safeX = (window.innerWidth - safeWidth) / 2
    const saveY = (window.innerHeight - safeHeight) / 2

    const visibleAreaWidth = Math.min(window.innerWidth, this.state.videoWidth)
    const visibleAreaHeight = Math.min(window.innerHeight, this.state.videoHeight)

    const visibleAreaX = Math.max((actualVideoWidth - window.innerWidth) / 2, 0)
    const visibleAreaY = Math.max((actualVideoHeight - window.innerHeight) / 2, 0)

    const actionSafeStyle = {
      position: "fixed",
      display: "flex",
      top: saveY,
      left: safeX,
      width: safeWidth,
      height: safeHeight,
      pointerEvents: "none"
    };

    const visibleAreaStyle = {
      position: "absolute",
      display: "flex",
      top: visibleAreaY,
      left: visibleAreaX,
      width: visibleAreaWidth,
      height: visibleAreaHeight,
      pointerEvents: "none"
    };

    return (
      <div className="ViewPortHandler no-overflow" style={viewPortStyle}>
        <div className="aspect-ratio-box" style={boxStyle} >
          {this.props.children}
          <div className="box-visible" style={visibleBoxStyle} />
          <div className="box-action-safe" style={actionSafeStyle}>
            {this.props.actionSafeChildren}
          </div>
          <div className="box-visible-area" style={visibleAreaStyle}>

          </div>
        </div>
      </div>
    );
  }
}

ViewPortHandler.propTypes = {
  widthDivider: PropTypes.number,
  heightDivider: PropTypes.number
}

ViewPortHandler.defaultProps = {
  widthDivider: 1,
  heightDivider: 1,
}

export default ViewPortHandler;