import { Howler } from "howler";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import App from "./App";
import PortraitDetector from "./components/Mobile/PortraitDetector/PortraitDetector";
import PortraitFallback from "./components/Mobile/PortraitFallback/PortraitFallback";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import packageJson from "../package.json";

// Declare gTM dataLayer array.
declare global {
  interface Window {
    gtag: (key: string, value: any, data?: any) => void;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <meta content="internal-version" name={packageJson.version} />
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <PortraitDetector portraitFallback={<PortraitFallback />}>
          <App />
        </PortraitDetector>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
