import Lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import introLottie from "../../lotties/000_intro_part1_0_135_part2_136_172_toplayer_only.json";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./PreIntro.css";

function PreIntro() {
  const animation = useRef<AnimationItem | null>(null);
  const { t } = useTranslation();
  const [videoState, setVideoState] = useState(VideoState.Play);
  const history = useHistory();

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementById("intro-animation") as Element,
        animationData: introLottie,
        autoplay: true,
        renderer: "svg",
        initialSegment: [0, 135],
        loop: false,
      });
    }

    const forcePush = setTimeout(() => {
      if (videoState === VideoState.Play) {
        setVideoState(VideoState.Transition);
        history.push("/intro");
      }
    }, 7000);

    return () => {
      animation.current?.destroy();
      animation.current = null;
      clearTimeout(forcePush);
    };
  }, []);

  const imageSrc = "000_intro_part1.last";
  const videoProps: VideoProps = {
    src: "000_intro_part1",
    muted: true,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      history.push("/intro");
    },
  };

  return (
    <div id="intro">
      <div
        id="intro-content"
        className="full-size"
        style={{ height: "100vh", position: "relative" }}
      >
        <VideoTransition
          videoState={videoState}
          image={imageSrc}
          videoProps={videoProps}
        >
          <div
            key="intro-animation"
            id="intro-animation"
            className="full-page full-size"
          ></div>
        </VideoTransition>
      </div>
    </div>
  );
}

export default PreIntro;
