import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fadeIn, fadeOut } from "../../Animations";
import RedCircleButton from "../Buttons/RedCircleButton/RedCircleButton";
import { isMobile, isTablet } from "../Mobile/MobileMediaDetector";
import MultiSourceImage from "../MultiSourceImage/MultiSourceImage";
import SceneOverlay, { SceneOverlayType } from "../SceneOverlay/SceneOverlay";
import { VideoState } from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import ViewPortHandler from "../VideoLibrary/Video/ViewPortHandler/ViewPortHandler";
import "./TrackOverview.css";

/**
 * Enum to represent the info window individual Red-Circle-Buttons.
 */
export enum ActiveInfo {
  None,
  Tunnel,
  Kart,
  RacingLine,
}

function TrackOverview() {
  const { t } = useTranslation();
  const [activeInfo, setActiveInfo] = useState(ActiveInfo.None);

  useEffect(() => {
    setTimeout(() => {
      fadeIn("#scene-overlay");
      fadeIn(".red-circles");
    }, 500);
  }, []);

  const onLeave = () => {
    setActiveInfo(ActiveInfo.None);
  };

  const onEnter = (info: ActiveInfo) => {
    setActiveInfo(info);
  };

  const onToggle = (info: ActiveInfo) => {
    if (activeInfo === info) {
      setActiveInfo(ActiveInfo.None);
    } else {
      setActiveInfo(info);
    }
  };

  function getInfoHeadline(): string | undefined {
    switch (activeInfo) {
      case ActiveInfo.Tunnel:
        return t("overview.tunnel.title");
      case ActiveInfo.Kart:
        return t("overview.cart.title");
      case ActiveInfo.RacingLine:
        return t("overview.line.title");
      default:
        return undefined;
    }
  }

  function getInfoText(): string | undefined {
    switch (activeInfo) {
      case ActiveInfo.Tunnel:
        return t("overview.tunnel.text");
      case ActiveInfo.Kart:
        return t("overview.cart.text");
      case ActiveInfo.RacingLine:
        return t("overview.line.text");
      default:
        return undefined;
    }
  }

  const isDesktop = !isMobile() && !isTablet();

  return (
    <div id="track-overview" className="full-page">
      <ViewPortHandler>
        <MultiSourceImage
          src={"trackinfo/trackinfo_overview"}
          pictureProps={{
            className: "full-page full-size",
          }}
        />
        <MultiSourceImage
          src="trackinfo/trackinfo_tunnel"
          pictureProps={{
            className:
              activeInfo === ActiveInfo.Tunnel
                ? "full-page full-size fadeIn"
                : "full-page full-size fadeOut",
          }}
        />
        <MultiSourceImage
          src="trackinfo/trackinfo_kart"
          pictureProps={{
            className:
              activeInfo === ActiveInfo.Kart
                ? "full-page full-size fadeIn"
                : "full-page full-size fadeOut",
          }}
        />
        <MultiSourceImage
          src="trackinfo/trackinfo_racingline"
          pictureProps={{
            className:
              activeInfo === ActiveInfo.RacingLine
                ? "full-page full-size fadeIn"
                : "full-page full-size fadeOut",
          }}
        />
        <RedCircleButton
          className={
            activeInfo == ActiveInfo.RacingLine
              ? "red-circles red-circles-active red-circle-rl"
              : activeInfo === ActiveInfo.None
              ? "red-circles red-circle-rl"
              : "red-circles red-circles-inactive red-circle-rl"
          }
          onEnter={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.RacingLine);
            }
          }}
          onLeave={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.None);
            }
          }}
          clicked={() => {
            if (!isDesktop) {
              onToggle(ActiveInfo.RacingLine);
            }
          }}
        />
        <RedCircleButton
          className={
            activeInfo == ActiveInfo.Kart
              ? "red-circles red-circles-active red-circle-kart"
              : activeInfo === ActiveInfo.None
              ? "red-circles red-circle-kart"
              : "red-circles red-circles-inactive red-circle-kart"
          }
          onEnter={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.Kart);
            }
          }}
          onLeave={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.None);
            }
          }}
          clicked={() => {
            if (!isDesktop) {
              onToggle(ActiveInfo.Kart);
            }
          }}
        />
        <RedCircleButton
          className={
            activeInfo == ActiveInfo.Tunnel
              ? "red-circles red-circles-active red-circle-tunnel"
              : activeInfo === ActiveInfo.None
              ? "red-circles red-circle-tunnel"
              : "red-circles red-circles-inactive red-circle-tunnel"
          }
          onEnter={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.Tunnel);
            }
          }}
          onLeave={() => {
            if (isDesktop) {
              onToggle(ActiveInfo.None);
            }
          }}
          clicked={() => {
            if (!isDesktop) {
              onToggle(ActiveInfo.Tunnel);
            }
          }}
        />

        <div
          onClick={() => {
            onToggle(ActiveInfo.None);
          }}
          className={
            activeInfo === ActiveInfo.None
              ? "info-window info-window-inactive"
              : "info-window info-window-active"
          }
        >
          <div>
            <h2>{getInfoHeadline()}</h2>
            <p>{getInfoText()}</p>
          </div>
        </div>
      </ViewPortHandler>
      <SceneOverlay
        videoState={VideoState.Ended}
        key="track-overview-overlay"
        onNavigate={() => {
          fadeOut("#track-overview");
        }}
        type={SceneOverlayType.TRACK}
      />
    </div>
  );
}

export default TrackOverview;
