import React from "react";
import { useTranslation } from "react-i18next";
import { GameState } from "../../Model/Challenge";
import "./ChallengeDescription.css";

export type ChallengeDescriptionProps = {
  number: number;
  text: string;
  gameState?: GameState;
  showFullText?: boolean;
};

function ChallengeDescription({
  number,
  text,
  gameState = undefined,
  showFullText = false,
}: ChallengeDescriptionProps) {
  const { t } = useTranslation();

  const backgroundNumber = number < 9 ? "0" + number : "" + number;

  return showFullText ? (
    <div id="challenge-description">
      <h1>{backgroundNumber}</h1>
      <div id="text-container">
        <h3>{t("overlay.challenge") + " " + number}</h3>
        <h2>{t(text)}</h2>
      </div>
    </div>
  ) : (
    <div id="challenge-description-number-only">
      <h1>{backgroundNumber}</h1>
    </div>
  );
}

export default ChallengeDescription;
