import React, { ReactNode, useEffect, useState } from "react";
import UAParser from "ua-parser-js";
import { uaParserInstance } from "../MobileMediaDetector";

export type PortraitDetectorProps = {
  portraitFallback?: any;
  children: ReactNode;
};

function PortraitDetector({
  children,
  portraitFallback = undefined,
}: PortraitDetectorProps) {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    });
  }, []);

  const device = uaParserInstance.getDevice();
  return device.type === UAParser.DEVICE.MOBILE && isPortrait
    ? portraitFallback
    : children;
}

export default PortraitDetector;
