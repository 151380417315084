import React, { useState } from "react";
import { useHistory } from "react-router";
import { ReactComponent as HomeIconCollapsed } from "../../../icons/home.svg";
import { ReactComponent as HomeIconExpanded } from "../../../icons/home_hover.svg";
import MobileDetector from "../../Mobile/MobileDetector/MobileDetector";
import { useTranslation } from "react-i18next";
import "./HomeButton.css";

export type HomeButtonProps = {
  onNavigate?: () => void;
};

function HomeButton({ onNavigate }: HomeButtonProps) {

  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const history = useHistory();

  return (
    <div
      id="home-button"
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      onClick={() => {
        history.push("/home");
        onNavigate?.();
      }}
    >
      <MobileDetector
        mobileFallback={
          <div id="home-button-icon-container">
            <HomeIconCollapsed id="home-button-icon" />
          </div>
        }
      >
        <h1 id="home-button-text">{t("overlay.home")}</h1>
        <div id="home-button-icon-container">
          {expanded ? (
            <HomeIconExpanded id="home-button-icon" />
          ) : (
            <HomeIconCollapsed id="home-button-icon" />
          )}
        </div>
      </MobileDetector>
    </div>
  );
}

export default HomeButton;
