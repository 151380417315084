import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fadeIn, fadeOut } from "../../../Animations";
import { validateEmail } from "../../../Helper";
import { ErrorResponse } from "../../../Networking";
import {
  PerformanceScore,
  PrivacyConsent,
  WebExpResult,
  submitWebExpResult,
} from "../../../api/WebExpService";
import CloseButton from "../../Buttons/CloseButton/CloseButton";
import RhombusButton from "../../Buttons/RhombusButton";
import MobileDetector from "../../Mobile/MobileDetector/MobileDetector";
import { isMobile } from "../../Mobile/MobileMediaDetector";
import ModalView from "../../ModalView/ModalView";
import LapTimeCalculator from "../../Model/LapTimeCalculator";
import MoreInfoVideo from "../../MoreInfoVideo/MoreInfoVideo";
import SceneOverlay, {
  SceneOverlayType,
} from "../../SceneOverlay/SceneOverlay";
import { VideoProps } from "../../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../../VideoLibrary/Video/VideoTransition/VideoTransition";
import "./Finish.css";
import FinishForm from "./FinishForm/FinishForm";
import FinishResult from "./FinishResult/FinishResult";

export enum EmailState {
  NOT_SENT,
  SENDING,
  SENT,
}

export type FinishProps = {
  lapTimeCalculator: LapTimeCalculator;
};

function Finish({ lapTimeCalculator }: FinishProps) {
  const { t, i18n } = useTranslation();

  const [videoState, setVideoState] = useState(VideoState.Play);
  const [emailState, setEmailState] = useState(EmailState.NOT_SENT);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [error, setError] = useState<string>();
  const [modalFormShowing, setModalFormShowing] = useState(false);

  const performanceScore = lapTimeCalculator.getPerformanceScore();
  let performance: string;
  switch (performanceScore) {
    case PerformanceScore.BEST:
      performance = "good";
      break;
    case PerformanceScore.MEDIOCRE:
      performance = "soso";
      break;
    case PerformanceScore.BAD:
      performance = "bad";
      break;
  }

  const videoProps: VideoProps = {
    src: i18n.language + "/080_finish_" + performance,
    muted: false,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Ended);
    },
  };

  useEffect(() => {
    setTimeout(() => {
      fadeIn("#finish-content");
    }, 500);
    if (!isMobile()) {
      setTimeout(() => {
        setShowMoreInfo(true);
      }, 10000);
    }
  }, []);

  const submitEmail = (email: string) => {
    const consent = [PrivacyConsent.RACE_EXPERIENCE, PrivacyConsent.MARKETING];

    if (emailState === EmailState.NOT_SENT) {
      if (validateEmail(email)) {
        setEmailState(EmailState.SENDING);
        submitWebExpResult(
          new WebExpResult(
            performanceScore,
            lapTimeCalculator.nextLapTime,
            lapTimeCalculator.penalties,
            email.toLowerCase(),
            "Linz",
            consent
          )
        )
          .then((data) => {
            if (data.ok) {
              setError(undefined);
              setEmailState(EmailState.SENT);
            } else {
              data.json().then((errorResponse: ErrorResponse) => {
                setEmailState(EmailState.NOT_SENT);
                if (
                  errorResponse.code === "ErrorUpdatingData" ||
                  errorResponse.code === "DataAlreadyExists"
                ) {
                  setError(t("finish.boost-exists"));
                } else if (errorResponse.code === "ValidationFailed") {
                  setError(t("finish.invalid-data"));
                } else if (errorResponse.code === "ErrorSendingEmail") {
                  setError("Unable to send confirmation email");
                } else if (errorResponse.code === "SmsTimingAuthFailed") {
                  setError("SMS Timing Auth error");
                } else if (errorResponse.code === "SmsTimingAPIError") {
                  setError("SMS Timing API error");
                } else {
                  setError(t("An error occurred, please try again"));
                }
              });
            }
          })
          .catch((reason) => {
            setError(`${reason}`);
            setEmailState(EmailState.NOT_SENT);
          });
      } else {
        setError(t("finish.invalid-email"));
      }
    } else {
      setError(t("finish.boost-exists"));
    }
  };

  return (
    <div id="finish-screen">
      <VideoTransition
        videoState={videoState}
        image={i18n.language + "/080_finish_" + performance + ".last"}
        videoProps={videoProps}
      />
      <div id="finish-content" className="flex-column opacity-0">
        <FinishResult lapTimeCalculator={lapTimeCalculator} />
        <MobileDetector
          mobileFallback={
            modalFormShowing ? (
              <ModalView showing={modalFormShowing}>
                <div
                  id="finish-form-modal"
                  className="full-page flex align-center justify-center"
                >
                  <CloseButton
                    id="finish-form-close"
                    onClick={() => setModalFormShowing(false)}
                  />
                  <FinishForm
                    key="mobile-finish-form"
                    emailState={emailState}
                    submitEmail={submitEmail}
                    error={error}
                  />
                </div>
              </ModalView>
            ) : (
              <RhombusButton
                text={t("finish.collect-boost")}
                onClick={() => setModalFormShowing(true)}
              />
            )
          }
        >
          <FinishForm
            key="finish-form"
            emailState={emailState}
            submitEmail={submitEmail}
            error={error}
          />
        </MobileDetector>
      </div>
      <SceneOverlay
        currentLapTime={lapTimeCalculator.nextLapTime}
        videoState={videoState}
        onNavigate={() => {
          fadeOut("#finish-content");
          fadeOut("#scene-overlay");
        }}
        type={SceneOverlayType.FINISH}
      />
      <MobileDetector
        mobileFallback={
          <div
            id="more-info-mobile"
            className="button"
            onClick={() => setShowMoreInfo(true)}
          >
            <p>{t("finish.moreInfo")}</p>
          </div>
        }
      >
        <div id="more-info-video" className={showMoreInfo ? "" : "closed"}>
          <MoreInfoVideo onClose={() => setShowMoreInfo(false)} />
        </div>
      </MobileDetector>
      {showMoreInfo && (
        <MobileDetector
          mobileFallback={
            <ModalView
              showing={true}
              className="full-page flex align-center"
              onClick={() => setShowMoreInfo(false)}
            >
              <div id="more-info-mobile-fullscreen">
                <MoreInfoVideo onClose={() => setShowMoreInfo(false)} />
              </div>
            </ModalView>
          }
        />
      )}
    </div>
  );
}

export default Finish;
