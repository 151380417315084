import Lottie, { AnimationItem, AnimationSegment } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AudioPlayer, { AudioProps } from "../../audio/AudioPlayer";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as Quote } from "../../icons/quote.svg";
import wurzLottie from "../../lotties/testimonial_thumb_in_0_49__active-in_50_69_active-loop_70_109_active-out_110_129_info-in_130_149_info-out_150_169.json";
import AnimatedParagraph from "../AnimatedParagraph/AnimatedParagraph";
import MobileDetector from "../Mobile/MobileDetector/MobileDetector";
import { isMobile } from "../Mobile/MobileMediaDetector";
import { ChallengeInfo, Game } from "../Model/Challenge";
import "./Testimonial.css";

export enum TestimonialState {
  ACTIVE,
  INACTIVE,
  MORE_INFO,
}

enum InternalTestimonialState {
  IN,
  ACTIVE_IN,
  ACTIVE_LOOP,
  ACTIVE_OUT,
  INFO_IN,
  INFO_OUT,
  INACTIVE,
}

export type TestimonialProps = {
  onClick?: () => void;
  onClose?: () => void;
  state: TestimonialState;
  text?: string;
  audioProps?: AudioProps;
  open: boolean;
  numberOfTries: number;
  challenge: ChallengeInfo;
};

export default function Testimonial({
  onClick = undefined,
  onClose = undefined,
  state,
  text = undefined,
  audioProps = undefined,
  open,
  numberOfTries,
  challenge,
}: TestimonialProps) {
  let showBorder = true;
  const animation = useRef<AnimationItem | null>(null);
  const { t, i18n } = useTranslation();
  const { audioFile, onAudioEnded, onAudioInitialized } = audioProps ?? {};
  const [showFullscreenSpeech, setShowFullscreenSpeech] = useState(
    numberOfTries === 1
  );
  const [internalState, setInternalState] = useState(
    numberOfTries > 1
      ? InternalTestimonialState.INACTIVE
      : state === TestimonialState.INACTIVE
      ? InternalTestimonialState.INACTIVE
      : challenge.gameType === Game.START
      ? InternalTestimonialState.IN
      : InternalTestimonialState.ACTIVE_IN
  );
  const [arrowId, setArrowId] = useState<undefined | string>(undefined);
  const mobile = isMobile();

  useEffect(() => {
    switch (state) {
      case TestimonialState.INACTIVE:
        setArrowId("testimonial-arrow-gone");
        showBorder = false;
        break;
      case TestimonialState.ACTIVE:
        setArrowId("testimonial-arrow-gone");
        break;
      case TestimonialState.MORE_INFO:
        if (internalState === InternalTestimonialState.INFO_IN) {
          setArrowId(open ? "testimonial-arrow-gone" : "testimonial-arrow");
        } else {
          setArrowId("testimonial-arrow-gone");
        }
        break;
    }
  }, [state, internalState]);

  function getFramesForState(): AnimationSegment {
    switch (internalState) {
      case InternalTestimonialState.IN:
        return [0, 49];
      case InternalTestimonialState.ACTIVE_IN:
        return [50, 69];
      case InternalTestimonialState.ACTIVE_LOOP:
        return [70, 109];
      case InternalTestimonialState.ACTIVE_OUT:
        return [110, 130];
      case InternalTestimonialState.INFO_IN:
        return [130, 149];
      case InternalTestimonialState.INFO_OUT:
        return [150, 169];
      default:
        return [48, 49];
    }
  }

  function getLoopForState(): boolean {
    switch (internalState) {
      case InternalTestimonialState.ACTIVE_LOOP:
        return true;
      default:
        return false;
    }
  }

  const animationComplete = () => {
    if (
      (state === TestimonialState.ACTIVE ||
        state === TestimonialState.MORE_INFO) &&
      internalState === InternalTestimonialState.IN
    ) {
      setInternalState(InternalTestimonialState.ACTIVE_IN);
    } else if (
      (state === TestimonialState.ACTIVE ||
        state === TestimonialState.MORE_INFO) &&
      internalState === InternalTestimonialState.ACTIVE_IN
    ) {
      setInternalState(InternalTestimonialState.ACTIVE_LOOP);
    } else if (
      state === TestimonialState.MORE_INFO &&
      internalState === InternalTestimonialState.ACTIVE_OUT
    ) {
      setInternalState(InternalTestimonialState.INFO_IN);
    }
  };

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementsByClassName(
          "testimonial-" + challenge.id + ""
        )[0] as Element,
        animationData: wurzLottie,
        autoplay: true,
        renderer: "svg",
        initialSegment: getFramesForState(),
        loop: getLoopForState(),
      });
      animation.current.addEventListener("complete", animationComplete);
    } else {
      animation.current.removeEventListener("complete");
      animation.current.addEventListener("complete", animationComplete);

      animation.current.playSegments(getFramesForState());
      animation.current.loop = getLoopForState();
    }
  }, [internalState, state]);

  useEffect(() => {
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  const fullscreenSpeech = (text: string) => {
    return showFullscreenSpeech ? (
      <div
        id="testimonial-text-container-mobile"
        className="full-page flex justify-center align-center"
        onClick={() => {
          setShowFullscreenSpeech(false);
          onClose?.();
        }}
      >
        <div className="flex-25" />

        <AnimatedParagraph
          wrapper="p"
          className="flex-50"
          text={t(text)}
          delay={50}
        />

        <div
          id="testimonial-text-mobile-close"
          className="flex-25 flex-column justify-center align-center button"
        >
          <CloseIcon style={{ width: "24px", height: "24px" }} />
          <p>{t("Close")}</p>
        </div>
      </div>
    ) : (
      <div />
    );
  };
  const showSpokenText =
    (state === TestimonialState.ACTIVE ||
      (state === TestimonialState.MORE_INFO && !open)) &&
    text !== undefined;

  const testimonialClickable =
    onClick !== undefined && (!showFullscreenSpeech || !mobile);
  return (
    <div>
      {audioProps && (
        <AudioPlayer
          key={audioFile}
          audioFile={i18n.language + "/" + audioFile}
          onAudioEnded={() => {
            if (animation.current) {
              setInternalState(InternalTestimonialState.ACTIVE_OUT);
            }
            onAudioEnded?.();
          }}
          onAudioPaused={() => {
            if (animation.current) {
              setInternalState(InternalTestimonialState.ACTIVE_OUT);
            }
          }}
          onAudioInitialized={onAudioInitialized}
        />
      )}
      <div
        id="testimonial-container"
        onClick={() => {
          if (testimonialClickable) {
            onClick?.();
          }
        }}
      >
        {mobile && showSpokenText && fullscreenSpeech(text ?? "")}
        <div
          id="testimonial-image"
          className={
            "testimonial-" +
            challenge.id +
            " " +
            (testimonialClickable ? " button" : "")
          }
        ></div>
        {!mobile && arrowId !== undefined && (
          <div id={arrowId}>
            <Arrow stroke="var(--color-yellow)" />
          </div>
        )}
      </div>
      {showSpokenText && (
        <MobileDetector>
          <div id="testimonial-spoken-text" key={"spoken-text" + text}>
            <AnimatedParagraph
              id="animated-paragraph"
              key={text}
              wrapper="p"
              text={t(text ?? "")}
              uniqueIdentifier={challenge.id}
              delay={50}
            />
            <Quote id="quote-icon" />
          </div>
        </MobileDetector>
      )}
    </div>
  );
}
