import Lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAudio from "../../../audio/Audio";
import startGame from "../../../lotties/020_start_0_171.json";
import RedCircleButton from "../../Buttons/RedCircleButton/RedCircleButton";
import Dialog from "../../Dialog/Dialog";
import { TrackGameProps } from "../TrackGameProps";
import "./TrackStart.css";

function TrackStart({ onComplete, onFailed }: TrackGameProps) {
  const { t } = useTranslation();

  const missStartDelay = 2500;
  const animation = useRef<AnimationItem | null>(null);
  const timeStamp = useRef<number | null>(null);
  const [jumpStart, setJumpStart] = useState(false);
  const [correctStart, setCorrectStart] = useState(false);
  const [missedStart, setMissedStart] = useState(false);
  const startAudio = useAudio("021_start_sound");

  const onProgress = () => {
    const totalFrames = animation?.current?.totalFrames ?? 249;
    const currentFrame = animation.current?.currentFrame ?? 0;
    const progressPercent = (currentFrame / totalFrames) * 100;

    // allow start at 97% because animation fades out
    if (timeStamp.current === null && progressPercent > 97) {
      timeStamp.current = Date.now();
    }
  };

  useEffect(() => {
    animation.current = Lottie.loadAnimation({
      container: document.getElementById("start-animation") as Element,
      animationData: startGame,
      autoplay: true,
      renderer: "svg",
      loop: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    });
    animation.current?.addEventListener("enterFrame", onProgress);
    animation.current?.addEventListener("complete", () => {
      setTimeout(() => {
        if (!jumpStart && !correctStart) {
          // show missed start dialog
          setMissedStart(true);
        }
      }, missStartDelay);
    });
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  useEffect(() => {
    if (!jumpStart && !missedStart && !startAudio.audio?.playing()) {
      startAudio.play();
    }
  }, [startAudio]);

  const completeGame = () => {
    //startAudio.fade(1, 0, 100);
    startAudio.stop();
    if (timeStamp.current === null) {
      // not yet pressed
      animation.current?.pause();
      setJumpStart(true);
    } else {
      setCorrectStart(true);
      const delta = (Date.now() - timeStamp.current) / 1000;
      onComplete(delta);
    }
  };

  return (
    <div id="track-start" className="full-page">
      <div
        key="start-animation"
        id="start-animation"
        className="full-page full-size"
      ></div>
      {jumpStart && (
        <Dialog
          header={t("start.dialog-jump.header")}
          title={t("start.dialog-jump.title")}
          message={t("start.dialog-jump.message")}
          buttonProps={{
            onClick: () => {
              onFailed?.();
            },
            text: "OK",
          }}
        />
      )}
      {missedStart && (
        <Dialog
          header={t("start.dialog-missed.header")}
          title={t("start.dialog-missed.title")}
          message={t("start.dialog-missed.message")}
          buttonProps={{
            onClick: () => {
              onFailed?.();
            },
            text: "OK",
          }}
        />
      )}
      {!missedStart && (
        <RedCircleButton
          className="red-circle-accelerate"
          text={t("start.red-circle")}
          onDown={completeGame}
        />
      )}
    </div>
  );
}

export default TrackStart;
