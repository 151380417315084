import { PerformanceScore } from "../../api/WebExpService";
import { Game } from "./Challenge";

export default class LapTimeCalculator {
  currentLapTime: number = 0;
  nextLapTime: number = 0;
  penalties: Map<Game, number> = new Map();
  currentGame?: Game;

  reset() {
    this.currentLapTime = 0;
    this.nextLapTime = 0;
    this.penalties = new Map();
    this.currentGame = undefined;
  }

  getReaction(): number {
    const percent = 100 - (this.penalties.get(Game.START) ?? 1) * 100;
    return Math.max(0, percent);
  }

  getCorners(): number {
    const percent1 = 100 - (this.penalties.get(Game.LINE1) ?? 3.3) * 33;
    const percent2 = 100 - (this.penalties.get(Game.LINE2) ?? 3.3) * 33;
    const percent = (percent1 + percent2) / 2;
    return Math.max(0, percent);
  }

  getTunnel(): number {
    const percent = 100 - (this.penalties.get(Game.TUNNEL) ?? 4) * 25;
    return Math.max(0, percent);
  }

  getBreaking(): number {
    const percent = 100 - (this.penalties.get(Game.BREAK) ?? 4) * 25;
    return Math.max(0, percent);
  }

  getPerformanceScore() {
    if (this.currentGame !== Game.BOOST || this.nextLapTime < 33) {
      // not finished
      return PerformanceScore.BAD;
    }
    if (this.nextLapTime < 35) {
      return PerformanceScore.BEST;
    } else if (this.nextLapTime < 37) {
      return PerformanceScore.MEDIOCRE;
    } else {
      return PerformanceScore.BAD;
    }
  }

  add(game: Game, withPenalty: number = 0) {
    this.currentGame = game;
    this.currentLapTime = this.nextLapTime;
    this.nextLapTime += withPenalty;
    this.penalties.set(game, withPenalty);

    switch (game) {
      case Game.START:
        this.nextLapTime += 5;
        break;
      case Game.LINE1:
        this.nextLapTime += 3;
        break;
      case Game.TUNNEL:
        this.nextLapTime += 9;
        break;
      case Game.BREAK:
        this.nextLapTime += 6;
        break;
      case Game.LINE2:
        this.nextLapTime += 7;
        break;
      case Game.BOOST:
        this.nextLapTime += 3;
        break;
    }
  }
}
