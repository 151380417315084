// Copyright Butleroy GmbH 2020

import React, { MutableRefObject, useEffect, useRef } from "react";
import MultiSourceImage from "../../../MultiSourceImage/MultiSourceImage";
import MultiSourceVideo, { VideoProps } from "../MultiSourceVideo";
import ViewPortHandler from "../ViewPortHandler/ViewPortHandler";
import "./VideoTransition.css";

export enum VideoState {
  Play,
  Ended,
  Transition,
}

export type VideoTransitionProps = {
  videoState: VideoState;
  hideVideo?: boolean;
  image: any;
  hoverImage?: string;
  videoProps: VideoProps;
  children?: React.ReactNode;
  actionSafeChildren?: React.ReactNode;
};

const VideoTransition = React.forwardRef<
  HTMLVideoElement,
  VideoTransitionProps
>(
  (
    {
      videoState,
      hideVideo = false,
      image,
      hoverImage,
      videoProps,
      children = undefined,
      actionSafeChildren,
    }: VideoTransitionProps,
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const onVideoEnded = videoProps.onEnded;

    videoProps.onEnded = () => {
      videoRef.current?.pause();
      if (onVideoEnded) {
        onVideoEnded();
      }
    };

    const videoEnded = videoState === VideoState.Ended;
    const imageOpacity = videoEnded ? 1 : 0; // needed if a scene should stay in ended (when returning from hotpost)

    useEffect(() => {
      if (ref && videoRef.current)
        (ref as MutableRefObject<HTMLVideoElement>).current = videoRef.current;
    }, [videoRef.current]);

    useEffect(() => {
      switch (videoState) {
        case VideoState.Ended | VideoState.Transition:
          videoRef.current?.pause();
          break;
        case VideoState.Play:
          videoRef.current?.play().catch((error) => {
            // most likely we must play muted
            if (videoRef.current) {
              videoRef.current.muted = true;
            }
            videoRef.current?.play().catch((error) => console.log(error));
          });
          break;
      }
    });

    return (
      <ViewPortHandler
        widthDivider={1}
        heightDivider={1}
        actionSafeChildren={actionSafeChildren}
      >
        <div className="full-page full-size no-overflow">
          {!hideVideo && (
            <MultiSourceVideo
              ref={videoRef}
              {...videoProps}
              key={"video-key-" + videoProps.src}
            />
          )}
        </div>
        <MultiSourceImage
          src={image}
          pictureProps={{
            className:
              "full-page full-size opacity-0 video-image-fade no-overflow",
            style: { opacity: imageOpacity },
          }}
        />
        {hoverImage && (
          <MultiSourceImage
            src={hoverImage}
            pictureProps={{
              className:
                "full-page full-size opacity-0 video-image-hover no-overflow",
            }}
          />
        )}
        {children}
      </ViewPortHandler>
    );
  }
);
export default VideoTransition;
