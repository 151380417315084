import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as StopWatch } from "../../../icons/stopwatch.svg";
import "./LapTime.css";

export type LapTimeProps = {
  lapTime?: number;
  nextLapTime?: number;
  staticLapTime?: number; // a fixed lap time to display
};

function LapTime({ lapTime = 0, nextLapTime = 0, staticLapTime = undefined }: LapTimeProps) {
  const { t } = useTranslation();

  const [currentLaptTime, setCurrentLapTime] = useState(lapTime * 1000);

  useEffect(() => {

    if(staticLapTime === undefined) {
      const step = 20;
      const dif = nextLapTime * 1000 - currentLaptTime;
      const iterations = dif / step;
      for (var i = 0; i < iterations; i++) {
        const internalI = i;
        setTimeout(() => {
          setCurrentLapTime(
            Math.min(lapTime * 1000 + step * 1.25 * internalI, nextLapTime * 1000)
          );
        }, (step / 1.25) * internalI);
      }
    }

  }, []);

  return (
    <div className="flex justify-center align-center" id="lap-time">
      <StopWatch id="lap-time-stopwatch" stroke="#ffffff" />
      <div>
        {staticLapTime === undefined && lapTime !== undefined && (
          <h4>{new Date(currentLaptTime).toISOString().substr(14, 8)}</h4>
        )}
        {staticLapTime && <h4>{new Date(staticLapTime * 1000).toISOString().substr(14, 8)}</h4>}
        <p>{t("overlay.lap-time")}</p>
      </div>
    </div>
  );
}

export default LapTime;
