import { Howl } from "howler";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { fadeOut } from "../../Animations";
import AudioContext from "../../Context/AudioContext";
import TrackBoost from "../Games/TrackBoost/TrackBoost";
import TrackBrake from "../Games/TrackBrake/TrackBrake";
import TrackCorner from "../Games/TrackCorner/TrackCorner";
import TrackStart from "../Games/TrackStart/TrackStart";
import TrackTunnel from "../Games/TrackTunnel/TrackTunnel";
import Scene from "../Scenes/Scene";
import { TestimonialState } from "../Testimonial/Testimonial";
import { VideoState } from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import LapTimeCalculator from "./LapTimeCalculator";
import TestimonialModel from "./TestimonialModel";
import { useTranslation } from "react-i18next";
import useAudio, { UseAudioOptions } from "../../audio/Audio";

export enum Game {
  START = 1,
  LINE1 = 2,
  TUNNEL = 3,
  BREAK = 4,
  LINE2 = 5,
  BOOST = 6,
}

export type ChallengeInfo = {
  id: number;
  name: string;
  gameType: Game;
  imageSrc: string;
  videoSrc: string;
  testimonialModel: TestimonialModel;
  mainButtonText: string;
  path: string;
  nextPath: string;
  testimonialStateForGameState: (gameState: GameState) => TestimonialState;
};

export type ChallengeProps = {
  challenge: ChallengeInfo;
  lapTimeCalculator: LapTimeCalculator;
};

export enum GameState {
  BEFORE_GAME,
  DURING_GAME,
  AFTER_GAME,
}

function Challenge({ challenge, lapTimeCalculator }: ChallengeProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [numberOfTries, setNumberOfTries] = useState(1);
  const [videoState, setVideoState] = useState(VideoState.Ended);
  const penalty = useRef(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const audioContext = useContext(AudioContext);
  const testimonialAudio = useRef<UseAudioOptions | undefined>(undefined);
  const moreInfoAudio = useRef<UseAudioOptions | undefined>(undefined);
  const moreInfo = useAudio(i18n.language + "/020_start_moreInfo");

  useEffect(() => {
    moreInfoAudio.current = moreInfo.audio;
  }, [moreInfo.audio]);

  const initialGameState =
    challenge.gameType === Game.START
      ? GameState.BEFORE_GAME
      : GameState.DURING_GAME;
  const [gameState, setGameState] = useState(initialGameState);

  const finishScene = (lastScene: boolean) => {
    if (videoRef.current) {
      // hack needed to get sound on video on mobile
      videoRef.current.muted = !audioContext.audioEnabled;
      videoRef.current.play().catch((e) => {
        if (videoRef.current) {
          videoRef.current.muted = true;
          videoRef.current.play();
        }
      });
    }
    lapTimeCalculator.add(challenge.gameType, penalty.current);
    setVideoState(VideoState.Play);
    if (lastScene) {
      setTimeout(() => {
        fadeOut("#scene-overlay");
        fadeOut("#scene-testimonial");
      }, 2500);
    }
    fadeOut("#scene-main-button");
  };

  let game: JSX.Element | undefined = undefined;
  switch (challenge.gameType) {
    case Game.START:
      game = (
        <TrackStart
          key={numberOfTries}
          game={challenge.gameType}
          onFailed={() => {
            setNumberOfTries(numberOfTries + 1);
          }}
          onComplete={(p) => {
            penalty.current = p;
            setGameState(GameState.AFTER_GAME);
            finishScene(false);
          }}
        />
      );
      break;
    case Game.LINE1:
    case Game.LINE2:
      game = (
        <TrackCorner
          game={challenge.gameType}
          onComplete={(p) => {
            penalty.current = p;
            testimonialAudio.current?.stop();
            setGameState(GameState.AFTER_GAME);
          }}
          onInteraction={() => {
            testimonialAudio.current?.stop();
          }}
        />
      );
      break;
    case Game.TUNNEL:
      game = (
        <TrackTunnel
          game={challenge.gameType}
          onComplete={(p) => {
            penalty.current = p;
            setGameState(GameState.AFTER_GAME);
            finishScene(false);
          }}
        />
      );
      break;
    case Game.BREAK:
      game = (
        <TrackBrake
          game={challenge.gameType}
          onComplete={(p) => {
            penalty.current = p;
            testimonialAudio.current?.stop();
            setGameState(GameState.AFTER_GAME);
          }}
          onInteraction={() => {
            testimonialAudio.current?.stop();
          }}
        />
      );
      break;
    case Game.BOOST:
      game = (
        <TrackBoost
          game={challenge.gameType}
          onComplete={(p) => {
            penalty.current = p;
            testimonialAudio.current?.stop();
            setGameState(GameState.AFTER_GAME);
            finishScene(true);
          }}
        />
      );
      break;
  }

  return (
    <AudioContext.Consumer>
      {({ audioEnabled }) => {
        if (!audioEnabled) {
          testimonialAudio.current?.stop();
        }
        return (
          <Scene
            key={"scene " + challenge.path}
            mainButtonText={
              gameState === GameState.AFTER_GAME
                ? t("games.next")
                : t(challenge.mainButtonText)
            }
            mainButtonClick={() => {
              testimonialAudio.current?.stop();
              if (gameState === GameState.AFTER_GAME) {
                finishScene(false);
              } else if (gameState === GameState.BEFORE_GAME) {
                setGameState(GameState.DURING_GAME);
                fadeOut("#scene-main-button");
              }
            }}
            onAudioInitialized={(a) => {
              testimonialAudio.current = a;
              if (audioEnabled && numberOfTries === 1) {
                setTimeout(() => {
                  testimonialAudio.current?.play();
                }, 1000);
              }
            }}
            onAudioEnded={() => {
              if (challenge.gameType === Game.START) {
                setTimeout(() => {
                  moreInfoAudio.current?.play();
                }, 1500);
              }
            }}
            videoProps={{
              src: challenge.videoSrc,
              muted: !audioEnabled,
              className: "",
              onEnded: () => {
                setVideoState(VideoState.Transition);
                history.push("/" + challenge.nextPath);
              },
            }}
            videoState={videoState}
            gameState={gameState}
            gameElement={game}
            lapTime={
              videoState === VideoState.Play
                ? lapTimeCalculator.currentLapTime
                : lapTimeCalculator.nextLapTime
            }
            nextLapTime={lapTimeCalculator.nextLapTime}
            challenge={challenge}
            numberOfTries={numberOfTries}
            videoRef={videoRef}
          />
        );
      }}
    </AudioContext.Consumer>
  );
}

export default Challenge;

export const challenges: Array<ChallengeInfo> = [
  {
    id: 1,
    gameType: Game.START,
    name: "start.name",
    imageSrc: "games/020_start.first",
    videoSrc: "games/020_start",
    path: "start",
    nextPath: "corner1",
    mainButtonText: "start.button",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.BEFORE_GAME:
          return TestimonialState.MORE_INFO;
        default:
          return TestimonialState.INACTIVE;
      }
    },
    testimonialModel: new TestimonialModel(
      {
        text: "start.testimonial-pre.text",
        audioFile: "020_start",
      },
      undefined,
      undefined,
      {
        number: 1,
        name: "start.more-info.name",
        items: [
          "start.more-info.item1",
          "start.more-info.item2",
          "start.more-info.item3",
          "start.more-info.item4",
          "start.more-info.item5",
        ],
      }
    ),
  },
  {
    id: 2,
    gameType: Game.LINE1,
    name: "corner1.name",
    imageSrc: "games/020_start.last",
    videoSrc: "games/030_corner1",
    path: "corner1",
    nextPath: "tunnel",
    mainButtonText: "corner.button",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.AFTER_GAME:
          return TestimonialState.MORE_INFO;
        default:
          return TestimonialState.ACTIVE;
      }
    },
    testimonialModel: new TestimonialModel(
      undefined,
      {
        text: "corner1.testimonial-during.text",
        audioFile: "030_corner1_01",
      },
      {
        text: "corner1.testimonial-post.text",
        audioFile: "030_corner1_02",
      },
      {
        number: 2,
        name: "corner1.more-info.name",
        headline: "corner1.more-info.headline",
        items: [
          "corner1.more-info.item1",
          "corner1.more-info.item2",
          "corner1.more-info.item3",
          "corner1.more-info.item4",
          "corner1.more-info.item5",
        ],
        images: ["more/more_info_corner1_01"],
      }
    ),
  },
  {
    id: 3,
    gameType: Game.TUNNEL,
    name: "tunnel.name",
    imageSrc: "games/030_corner1.last",
    videoSrc: "games/040_tunnel",
    path: "tunnel",
    nextPath: "brake",
    mainButtonText: "",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.AFTER_GAME:
          return TestimonialState.INACTIVE;
        default:
          return TestimonialState.MORE_INFO;
      }
    },
    testimonialModel: new TestimonialModel(
      undefined,
      {
        text: "tunnel.testimonial-during.text",
        audioFile: "040_tunnel",
      },
      undefined,
      {
        number: 3,
        name: "tunnel.more-info.name",
        headline: "tunnel.more-info.headline",
        items: [
          "tunnel.more-info.item1",
          "tunnel.more-info.item2",
          "tunnel.more-info.item3",
          "tunnel.more-info.item4",
          "tunnel.more-info.item5",
        ],
      }
    ),
  },
  {
    id: 4,
    gameType: Game.BREAK,
    name: "brake.name",
    imageSrc: "games/040_tunnel.last",
    videoSrc: "games/050_brake",
    path: "brake",
    nextPath: "corner2",
    mainButtonText: "",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.AFTER_GAME:
          return TestimonialState.MORE_INFO;
        default:
          return TestimonialState.ACTIVE;
      }
    },
    testimonialModel: new TestimonialModel(
      undefined,
      {
        text: "brake.testimonial-during.text",
        audioFile: "050_brake_01",
      },
      {
        text: "brake.testimonial-post.text",
        audioFile: "050_brake_02",
      },
      {
        number: 4,
        name: "brake.more-info.name",
        items: [
          "brake.more-info.item1",
          "brake.more-info.item2",
          "brake.more-info.item3",
        ],
        images: ["more/more_info_brake_good", "more/more_info_brake_bad"],
      }
    ),
  },
  {
    id: 5,
    gameType: Game.LINE2,
    name: "corner2.name",
    imageSrc: "games/050_brake.last",
    videoSrc: "games/060_corner2",
    path: "corner2",
    nextPath: "boost",
    mainButtonText: "corner.button",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.AFTER_GAME:
          return TestimonialState.MORE_INFO;
        default:
          return TestimonialState.ACTIVE;
      }
    },
    testimonialModel: new TestimonialModel(
      undefined,
      {
        text: "corner2.testimonial-during.text",
        audioFile: "060_corner2_01",
      },
      {
        text: "corner2.testimonial-post.text",
        audioFile: "060_corner2_02",
      },
      {
        number: 5,
        name: "corner2.more-info.name",
        headline: "corner2.more-info.headline",
        items: [
          "corner2.more-info.item1",
          "corner2.more-info.item2",
          "corner2.more-info.item3",
          "corner2.more-info.item4",
        ],
        images: ["more/more_info_corner2_01"],
      }
    ),
  },
  {
    id: 6,
    gameType: Game.BOOST,
    name: "boost.name",
    imageSrc: "games/060_corner2.last",
    videoSrc: "games/070_boost",
    path: "boost",
    nextPath: "finish",
    mainButtonText: "",
    testimonialStateForGameState: (gameState) => {
      switch (gameState) {
        case GameState.DURING_GAME:
          return TestimonialState.MORE_INFO;
        default:
          return TestimonialState.ACTIVE;
      }
    },
    testimonialModel: new TestimonialModel(
      undefined,
      {
        text: "boost.testimonial-during.text",
        audioFile: "070_boost",
      },
      undefined,
      {
        number: 6,
        name: "boost.more-info.name",
        items: [
          "boost.more-info.item1",
          "boost.more-info.item2",
          "boost.more-info.item3",
          "boost.more-info.item4",
          "boost.more-info.item5",
        ],
        images: ["more/more_info_boost_areas"],
      }
    ),
  },
];
