import React from "react";
import { useTranslation } from "react-i18next";
import MobileDetector from "../../Mobile/MobileDetector/MobileDetector";
import Share from "./Share";
import "./ShareElement.css";

function ShareElement() {
  const { t } = useTranslation();

  return (
    <div className="flex align-center button">
      <MobileDetector>
        <div id="share-content">
          <h5>{t("overlay.share.better-friends")}</h5>
          <p>{t("overlay.share.find-out")}</p>
        </div>
      </MobileDetector>
      <Share
        title="Rotax MAX Dome"
        text={t("overlay.share.check-out")}
        link="https://racex.rotaxmaxdome.com/linz"
      />
    </div>
  );
}

export default ShareElement;
