import React from "react";
import { useTranslation } from "react-i18next";
import UAParser from "ua-parser-js";
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator";
import { uaParserInstance } from "../Mobile/MobileMediaDetector";
import { ButtonProps } from "./ButtonProps";
import "./RhombusButton.css";

function RhombusButton({
  text,
  disabled = false,
  loading = false,
  onClick = undefined,
  className = undefined,
}: ButtonProps) {
  const internalOnClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    onClick?.();
  };

  const os = uaParserInstance.getOS();

  return (
    <button
      className={
        "button" +
        (className ? " " + className : "") +
        (os.name?.includes("Android") ? " android" : "")
      }
      id="rhombus-button"
      onClick={internalOnClick}
      disabled={disabled}
    >
      <div id="rhombus-button-content">
        <h5 className={loading ? "opacity-0" : ""}>{text}</h5>
        {loading && (
          <div className="full-page flex align-center justify-center">
            <ActivityIndicator />
          </div>
        )}
      </div>
    </button>
  );
}

export default RhombusButton;
