import { Howl, HowlOptions } from "howler";
import { useCallback, useEffect, useRef, useState } from "react";
import UAParser from "ua-parser-js";
import {
  isMobile,
  uaParserInstance,
} from "../components/Mobile/MobileMediaDetector";
import { environment } from "../environment";

export type UseAudioOptions = {
  audio?: Howl;
  play: () => void;
  stop: () => void;
};

const useAudio = (
  url: string,
  options: HowlOptions = {},
  timeout?: number,
  ignoreOnMobile: boolean = false,
  fileTypes: string[] = [".mp3"]
) => {
  options.src = fileTypes.flatMap(
    (fileType) => require("./" + url + fileType).default
  );
  options.preload = true;
  const isMounted = useRef(false);
  const HowlConstructor = useRef(Howl);
  const [audio, setAudio] = useState<Howl | undefined>(undefined);

  if (!environment.production) {
    console.log("HOWLER " + url + audio);
  }

  const setupAudio = () => {
    const device = uaParserInstance.getDevice();
    if (ignoreOnMobile && device.type === UAParser.DEVICE.MOBILE) {
      return;
    }

    if (!environment.production) {
      console.log("HOWLER INIT " + url);
    }
    if (!isMounted.current) {
      setAudio(new HowlConstructor.current(options));
      isMounted.current = true;
    }
    return () => {
      if (!environment.production) {
        console.log("HOWLER DEINIT " + url);
      }
      audio?.stop();
      audio?.unload();
    };
  };

  useEffect(() => {
    if (timeout) {
      setTimeout(setupAudio, timeout);
    } else {
      setupAudio();
    }
  }, []);

  const play = useCallback(() => {
    audio?.play();
  }, [isMounted.current]);

  const stop = useCallback(() => {
    audio?.stop();
  }, [isMounted.current]);

  return { audio, play, stop };
};

export default useAudio;
