import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CloseButton from "../Buttons/CloseButton/CloseButton";
import MultiSourceVideo from "../VideoLibrary/Video/MultiSourceVideo";
import "./MoreInfoVideo.css";

export type MoreInfoVideoProps = {
  onClose: () => void;
};

export default function MoreInfoVideo({ onClose }: MoreInfoVideoProps) {
  const { t } = useTranslation();

  return (
    <div id="more-info-video-container">
      <CloseButton id="more-info-close" onClick={onClose} />
      <div className="flex-60">
        <MultiSourceVideo
          src="maxdome_more_info_small"
          muted={true}
          playing={true}
          loop={true}
        />
      </div>
      <p id="more-info-bottom" className="flex-40">
        <a
          href="https://rotaxmaxdome.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("finish.moreInfoLong")}
        </a>
      </p>
    </div>
  );
}
