import { Howler } from "howler";
import Lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import "./App.css";
import AudioContext from "./Context/AudioContext";
import useAudio from "./audio/Audio";
import Home from "./components/Home/Home";
import Challenge, { challenges } from "./components/Model/Challenge";
import LapTimeCalculator from "./components/Model/LapTimeCalculator";
import Finish from "./components/Scenes/Finish/Finish";
import PostIntro from "./components/Scenes/PostIntro";
import PreIntro from "./components/Scenes/PreIntro";
import Redeem from "./components/Scenes/Redeem/Redeem";
import TrackOverview from "./components/TrackOverview/TrackOverview";
import TransitionRoute from "./components/VideoLibrary/TransitionRoute/TransitionRoute";
import "./global.css";
import "./i18n/config";

//https://stackoverflow.com/questions/48219432/react-router-typescript-errors-on-withrouter-after-updating-version

function App() {
  const routes = [];
  const backgroundAudio = useAudio(
    "background",
    {
      loop: true,
      volume: 0.25,
      autoplay: true,
    },
    3000,
    true,
    [".ogg", ".mp3"]
  );
  const [audioEnabled, setAudioEnabled] = useState(true);
  const toggleAudio = () => {
    const newValue = !audioEnabled;
    setAudioEnabled(newValue);
    // Howler.volume(newValue ? 1 : 0);
  };
  const { t } = useTranslation();
  const searchParams = useLocation().search;
  const lapTimeCalculator = useRef(new LapTimeCalculator());
  const [trackingEnabled, setTrackingEnabled] = useState(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        setAudioEnabled(false);
      } else if (document.visibilityState === "visible") {
        setAudioEnabled(true);
      }
    });
    Lottie.setQuality("low");

    console.log("PUBLIC URL: " + process.env.PUBLIC_URL);
  }, []);

  useEffect(() => {
    Howler.mute(!audioEnabled);
  }, [audioEnabled]);

  for (let challenge of challenges) {
    routes.push(
      <TransitionRoute key={challenge.path} path={"/" + challenge.path}>
        <Challenge
          key={challenge.id}
          challenge={challenge}
          lapTimeCalculator={lapTimeCalculator.current}
        />
      </TransitionRoute>
    );
  }

  routes.push(
    <TransitionRoute key="preintro" path="/">
      <PreIntro />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute key="postintro" path="/intro">
      <PostIntro />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute key="home" path="/home">
      <Home lapTimeCalculator={lapTimeCalculator.current} />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute key="track" path="/track-overview">
      <TrackOverview />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute key="finish" path="/finish">
      <Finish lapTimeCalculator={lapTimeCalculator.current} />
    </TransitionRoute>
  );
  routes.push(
    <TransitionRoute key="redeem" path="/redeem">
      <Redeem />
    </TransitionRoute>
  );

  /// if this parameter is not defined, true is assumed as default
  useEffect(() => {
    setTrackingEnabled(
      new URLSearchParams(searchParams)
        .get("trackingEnabled")
        ?.toLowerCase() !== "false"
    );
  }, []);

  const cookieConsentValue = "rmd-cookie-consent";
  useEffect(() => {
    // consent bar won't appear on the next app visit from the same browser: we have to handle GA init on every app run if a user granted prior consent
    const consentGiven = getCookieConsentValue(cookieConsentValue);
    if (consentGiven !== undefined && consentGiven.length > 0) {
      handleAcceptCookie(consentGiven === "true");
    }
  }, []);

  const handleAcceptCookie = (consentGiven: boolean) => {
    // Google Analytics no longer used
  };

  const handleDeclineCookie = () => {
    // Google Analytics no longer used
    handleAcceptCookie(false);
  };

  return (
    <div key="main-app" className="App">
      <AudioContext.Provider
        value={{ audioEnabled: audioEnabled, toggleAudio: toggleAudio }}
      >
        {routes}
      </AudioContext.Provider>
      {trackingEnabled && (
        <CookieConsent
          location="bottom"
          buttonText={t("cookies.accept")}
          declineButtonText={t("cookies.decline")}
          // debug={true}
          onAccept={() => {
            handleAcceptCookie(true);
          }}
          onDecline={handleDeclineCookie}
          cookieName={cookieConsentValue}
          style={{ background: "var(--color-background)" }}
          enableDeclineButton={true}
          buttonStyle={{
            color: "white",
            backgroundColor: "var(--color-rotax-red)",
            fontSize: "15px",
            padding: "12px",
            width: "120px",
          }}
          declineButtonStyle={{
            color: "white",
            backgroundColor: "var(--color-background)",
            fontSize: "15px",
            padding: "12px",
            width: "120px",
            border: "1px solid white",
          }}
          expires={150}
        >
          <p id="cookie-text">{t("cookies.text")}</p>
        </CookieConsent>
      )}
    </div>
  );
}

export default withRouter(App);
