import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { fadeOut } from "../../../Animations";
import { redeemBoosts } from "../../../api/WebExpService";
import { ErrorResponse } from "../../../Networking";
import AnimatedParagraph from "../../AnimatedParagraph/AnimatedParagraph";
import RhombusButton from "../../Buttons/RhombusButton";
import MultiSourceImage from "../../MultiSourceImage/MultiSourceImage";
import SceneOverlay, {
  SceneOverlayType,
} from "../../SceneOverlay/SceneOverlay";
import { VideoState } from "../../VideoLibrary/Video/VideoTransition/VideoTransition";
import ViewPortHandler from "../../VideoLibrary/Video/ViewPortHandler/ViewPortHandler";
import "./Redeem.css";

export default function Redeem() {
  const { t } = useTranslation();
  const queryString = require("query-string");
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [code, setCode] = useState<string>(params.code ?? "");
  const [raceLicenseId, setRaceLicenseId] = useState("");
  const [loading, setLoading] = useState(false);

  const submitCode = () => {
    setLoading(true);
    redeemBoosts({ code: code, raceLicenseId: raceLicenseId })
      .then((data) => {
        setLoading(false);
        if (data.ok) {
          window.alert(t("finish.boost-claimed"));
        } else {
          data.json().then((json: ErrorResponse) => {
            if (json.status === 400) {
              window.alert(t("finish.boost-exists"));
            } else {
              window.alert(t("An error occurred, please try again"));
            }
          });
        }
      })
      .catch((reason) => {
        setLoading(false);
        window.alert(reason);
      });
  };

  return (
    <div className="full-page">
      <ViewPortHandler>
        <MultiSourceImage
          className="full-page"
          src="de/080_finish_good.first"
        />
      </ViewPortHandler>
      <div
        id="redeem-page"
        className="full-page flex-column justify-center align-center"
      >
        <AnimatedParagraph wrapper="h1" text={t("redeem.headline")} />
        <AnimatedParagraph wrapper="p" text={t("redeem.text")} />
        <form>
          <div
            id="redeem-form-container"
            className="full-size flex justify-center align-end"
          >
            <label>
              <input
                id="redeem-code-input"
                placeholder={t("redeem.code")}
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
            <label>
              <input
                id="redeem-userid-input"
                placeholder={t("redeem.userId")}
                type="email"
                value={raceLicenseId}
                onChange={(e) => setRaceLicenseId(e.target.value)}
              />
            </label>
            <RhombusButton
              className="adjust-center"
              text={t("redeem.button")}
              disabled={loading}
              loading={loading}
              onClick={() => {
                if (code.length > 0 && raceLicenseId.length > 0) {
                  submitCode();
                }
              }}
            />
          </div>
        </form>
      </div>
      <SceneOverlay
        videoState={VideoState.Ended}
        onNavigate={() => {
          fadeOut("#redeem-page");
        }}
        type={SceneOverlayType.REDEEM}
      />
    </div>
  );
}
