import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UAParser from "ua-parser-js";
import { ReactComponent as ShareIcon } from "../../../icons/share.svg";
import { ReactComponent as Facebook } from "../../../icons/social/facebook.svg";
import { ReactComponent as Link } from "../../../icons/social/link.svg";
import { ReactComponent as Twitter } from "../../../icons/social/twitter.svg";
import { ReactComponent as WhatsApp } from "../../../icons/social/whatsapp.svg";
import {
  copyToClipboard,
  shareViaFacebook,
  shareViaTwitter,
  shareViaWhatsApp,
} from "../../../ShareHelper";
import { uaParserInstance } from "../../Mobile/MobileMediaDetector";
import "./Share.css";

export enum ShareType {
  NATIVE,
  SMS,
  MAIL,
}

export type ShareProps = {
  title: string;
  text: string;
  link: string;
};

function Share({ title, text, link }: ShareProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const device = uaParserInstance.getDevice().type;

  const shareText = text + " " + link;

  return (
    <div
      id="share-container"
      className="flex"
      onClick={() => setOpen(!open)}
      onMouseEnter={() => {
        if (device !== UAParser.DEVICE.MOBILE) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (device !== UAParser.DEVICE.MOBILE) {
          setOpen(false);
        }
      }}
    >
      <div className="flex">
        <div
          id="icon"
          className={open ? "expanded" : "collapsed"}
          onClick={() => shareViaTwitter(text, link)}
        >
          <Twitter fill="#ffffff" />
        </div>
        <div
          id="icon"
          className={open ? "expanded" : "collapsed"}
          onClick={() => shareViaFacebook(text, link)}
        >
          <Facebook fill="#ffffff" />
        </div>
        <div
          id="icon"
          className={open ? "expanded" : "collapsed"}
          onClick={() => shareViaWhatsApp(shareText)}
        >
          <WhatsApp />
        </div>
        <div
          id="icon"
          className={open ? "expanded" : "collapsed"}
          onClick={() => {
            if (device === UAParser.DEVICE.MOBILE) {
              copyToClipboard(link);
            } else {
              navigator.clipboard.writeText(link);
            }
            alert(t("finish.linkCopied"));
          }}
        >
          <Link />
        </div>
      </div>
      <div id="share-icon">
        <ShareIcon />
      </div>
    </div>
  );
}

export default Share;
