import React, { useRef, useState } from "react";
import MultiSourceImage from "../MultiSourceImage/MultiSourceImage";
import "./PeekThrough.css";

export type PeekProps = {
  className?: string;
  backgroundSrc: string;
  peekSrc: string;
  /** if not null / undefined, the PeekThrough component will adapt the "cursor" position based on the constraints provided by the ViewPortHandler */
  viewPortConstants?: any;
  onShowTrack: () => void;
  onClicked: () => void;
};

function PeekThrough({
  className = undefined,
  backgroundSrc,
  peekSrc,
  viewPortConstants,
  onShowTrack,
  onClicked,
}: PeekProps) {
  const idealRatioX = viewPortConstants.perfectRatioX;
  const maxRatioX = viewPortConstants.maxRatioX;
  const minRatioX = viewPortConstants.minRatioX;
  const idealRatioY = viewPortConstants.perfectRatioY;
  const maxRatioY = viewPortConstants.maxRatioY;
  const minRatioY = viewPortConstants.minRatioY;

  const peekDuration = 0.75; // seconds
  const peekLag = 75; // milliseconds
  const defaultPeek = 110; // size of the peek radius in pixels
  const peekFps = 30; // frames per second for the peek size animation
  const peekShowHideDuration = 0.15; // seconds

  const [peekRadius, setPeek] = useState(100);
  const [animated, setAnimated] = useState(false);
  const [animating, setAnimating] = useState(false);
  const mouseMoved = useRef(false);

  function animatePeek(
    fromPeek: number,
    toPeek: number,
    currentPeek: number,
    duration: number,
    finishCallback?: () => void
  ) {
    const dist = toPeek - fromPeek;
    const stepTime = 1000.0 / peekFps;
    const stepSize = dist / ((duration * 1000.0) / stepTime);

    setTimeout(() => {
      let newPeek = currentPeek + stepSize;
      if (dist > 0) {
        newPeek = Math.min(newPeek, toPeek);
      } else {
        newPeek = Math.max(newPeek, 0);
      }

      setPeek(newPeek);

      if ((dist > 0 && newPeek < toPeek) || (dist < 0 && newPeek > toPeek)) {
        animatePeek(fromPeek, toPeek, newPeek, duration, finishCallback);
      } else {
        finishCallback?.();
      }
    }, stepTime);
  }

  const clicked = (e: React.MouseEvent) => {
    onClicked();
    if (!animating) {
      setAnimating(true);

      const finish = () => {
        onShowTrack();
        setAnimated(!animated);
        setAnimating(false);
      };

      const max = Math.max(window.innerHeight, window.innerWidth);
      if (animated) {
        animatePeek(max, defaultPeek, peekRadius, peekDuration, finish);
      } else {
        animatePeek(defaultPeek, max, peekRadius, peekDuration, finish);
      }
    }
  };

  const [x, setPosX] = useState(0);
  const [y, setPosY] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const trackMouse = (e: React.MouseEvent) => {
    mouseMoved.current = true;
    if (!animating) {
      if (viewPortConstants) {
        const [ratioX, multiplierX] = calcRatioX();
        const x =
          e.clientX +
          (window.innerWidth - (window.innerWidth / ratioX) * multiplierX) / 2;

        const [ratioY, multiplierY] = calcRatioY();
        const y =
          e.clientY +
          (window.innerHeight - (window.innerHeight / ratioY) * multiplierY) /
            2;

        setTimeout(() => {
          setPosX(x);
          setPosY(y);
          setMouseX(e.clientX);
          setMouseY(e.clientY);
        }, peekLag);
      } else {
        setTimeout(() => {
          setPosX(e.clientX);
          setPosY(e.clientY);
        }, peekLag);
      }
    }
  };

  function calcRatioX(): [number, number] {
    const ratio = window.innerWidth / window.innerHeight;

    if (ratio >= idealRatioX) {
      return [Math.max(maxRatioX, ratio), maxRatioX];
    } else if (ratio < minRatioX) {
      return [Math.max(minRatioX, ratio), idealRatioX];
    } else if (ratio < 1.0) {
      return [Math.max(1.0, ratio), minRatioX];
    } else {
      return [Math.min(idealRatioX, ratio), idealRatioX];
    }
  }

  function calcRatioY(): [number, number] {
    const ratio = window.innerHeight / window.innerWidth;

    if (ratio >= idealRatioY) {
      return [Math.max(maxRatioY, ratio), maxRatioY];
    } else if (ratio < minRatioY) {
      return [Math.max(minRatioY, ratio), idealRatioY];
    } else {
      return [Math.min(idealRatioY, ratio), idealRatioY];
    }
  }

  const mouseEnter = (e: React.MouseEvent) => {
    if (!animating) {
      animatePeek(0, defaultPeek, peekRadius, peekShowHideDuration, undefined);
    }
  };

  const mouseLeave = (e: React.MouseEvent) => {
    if (!animating) {
      animatePeek(defaultPeek, 0, peekRadius, peekShowHideDuration, undefined);
    }
  };

  const peekBorder = 5;
  const peekDiameter = (peekRadius - peekBorder) * 2;
  const pos: string = "calc(" + x + "px + 100%) calc(" + y + "px + 100%)";

  const maskImage =
    "radial-gradient(circle at center, " +
    (peekRadius > defaultPeek ? "transparent" : "#FFFFFF59") +
    " " +
    peekRadius +
    "px, white " +
    peekRadius +
    "px)";

  return (
    <div
      id="peek-through"
      className={className}
      onClick={clicked}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onMouseMove={trackMouse}
    >
      <MultiSourceImage className="full-page" src={backgroundSrc} />
      <MultiSourceImage
        id="base"
        className="full-page"
        src={peekSrc}
        style={
          mouseMoved.current
            ? {
                maskSize:
                  window.innerWidth * 2 + "px " + window.innerHeight * 2 + "px",
                WebkitMaskSize:
                  window.innerWidth * 2 + "px " + window.innerHeight * 2 + "px",
                maskPosition: pos,
                WebkitMaskPosition: pos,
                maskImage: maskImage,
                WebkitMaskImage: maskImage,
              }
            : {}
        }
      />
      {mouseMoved.current && peekDiameter >= defaultPeek && (
        <div
          style={{
            borderRadius: "50%",
            border: peekBorder + "px solid black",
            position: "fixed",
            left: mouseX - peekRadius + "px",
            top: mouseY - peekRadius + "px",
            width: peekDiameter + "px",
            height: peekDiameter + "px",
          }}
        />
      )}
    </div>
  );
}

export default PeekThrough;
