import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import rotateLottie from "../../../lotties/ic_rotate_device.json";
import Lottie, { AnimationItem } from "lottie-web";
import { ReactComponent as Share } from "../../../icons/ios_share.svg";
import { ReactComponent as Menu } from "../../../icons/android_menu.svg";
import "./PortraitFallback.css";

function PortraitFallback() {
  const { t } = useTranslation();
  const animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementById("rotate-animation") as Element,
        animationData: rotateLottie,
        autoplay: true,
        renderer: "svg",
        loop: true,
      });
    }
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  return (
    <div className="full-page flex-column justify-center align-center">
      <div
        key="rotate-animation"
        id="rotate-animation"
        style={{ width: "50vmin" }}
      ></div>
      <div id="rotate-explanation-content">
        <p id="rotate-explanation-paragraph">{t("landing.experience")}</p>
        <ol id="rotate-explanation">
          <li>
            {t("landing.add-to-home1")}
            (<Share style={{ fill: "white", width: "18px", height: "18px" }} />,{" "}
            <Menu style={{ fill: "white", width: "18px", height: "14px" }} />)
          </li>
          <li>{t("landing.add-to-home2")}</li>
          <li>{t("landing.add-to-home3")}</li>
        </ol>
      </div>
    </div>
  );
}

export default PortraitFallback;
