import Lottie, { AnimationItem } from "lottie-web";
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import buttonAnimationIdle from "../../../lotties/btn_games_interaction_0_59.json";
import buttonAnimationActive from "../../../lotties/btn_games_interaction_active_0_24.json";
import "./RedCircleButton.css";
import { isMobile, isTablet } from "../../Mobile/MobileMediaDetector";

export type RedCircleButtonProps = {
  className?: string;
  clicked?: () => void;
  onDown?: () => void;
  onUp?: () => void;
  onEnter?: () => void;
  onLeave?: () => void;
  style?: React.StyleHTMLAttributes<HTMLDivElement>;
  text?: string;
  textBelow?: string;
  activated?: boolean;
};

function RedCircleButton({
  className,
  clicked,
  onDown,
  onUp,
  onEnter,
  onLeave,
  style = undefined,
  text,
  textBelow = undefined,
  activated = false,
}: RedCircleButtonProps) {
  let animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    animation.current?.destroy();
    animation.current = Lottie.loadAnimation({
      container: document.getElementById(
        "red-circle-button-animation-" + className
      ) as Element,
      animationData: activated
      ? buttonAnimationActive
      : buttonAnimationIdle,
      autoplay: true,
      loop: true,
      renderer: "svg",
    });
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, [activated]);

  const down = () => {
    onDown?.();
  };

  const up = () => {
    if (!isMobile() && !isTablet()) {
      onUp?.();
    }
  };

  const click = () => {
    clicked?.();
  };

  return (
    <div
      id="red-circle-button"
      className={className}
      onMouseDown={down}
      onTouchStart={down}
      onMouseUp={up}
      onMouseLeave={onLeave}
      onMouseEnter={onEnter}
      onClick={click}
    >
      <div
        className="red-circle-button-animation"
        id={"red-circle-button-animation-" + className}
      ></div>
      <div id="circle-button-text-container">
        {text && <div id="circle-button-text">{text}</div>}
      </div>
      <div>
        {textBelow && <p id="circle-button-text">{textBelow}</p>}
      </div>
    </div>
  );
}

export default RedCircleButton;
