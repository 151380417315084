import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  copyToClipboard,
  shareViaFacebook,
  shareViaTwitter,
  shareViaWhatsApp,
} from "../../../../ShareHelper";
import { ReactComponent as Facebook } from "../../../../icons/social/facebook.svg";
import { ReactComponent as Link } from "../../../../icons/social/link.svg";
import { ReactComponent as Twitter } from "../../../../icons/social/twitter.svg";
import { ReactComponent as WhatsApp } from "../../../../icons/social/whatsapp.svg";
import AnimatedParagraph from "../../../AnimatedParagraph/AnimatedParagraph";
import RhombusButton from "../../../Buttons/RhombusButton";
import { EmailState } from "../Finish";
import "./FinishForm.css";

export type FinishFormProps = {
  emailState: EmailState;
  submitEmail: (email: string) => void;
  error?: string;
};

export default function FinishForm({
  emailState,
  submitEmail,
  error,
}: FinishFormProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const finished = emailState === EmailState.SENT;

  const header3 = finished ? "finish.done1" : "finish.congratulations1";
  const header1 = finished ? "finish.done2" : "finish.congratulations2";
  const text = finished ? "finish.share" : "finish.extra-boost";

  const title = "Rotax MAX Dome";
  const shareText = t("overlay.share.check-out");
  const link = "https://racex.rotaxmaxdome.com/linz";
  const shareTextLong = shareText + " " + link;

  return (
    <div id="finish-form" className="flex-column align-center">
      <AnimatedParagraph
        wrapper="h3"
        className="text-center"
        text={t(header3)}
      />
      <AnimatedParagraph
        wrapper="h1"
        className="text-center"
        text={t(header1)}
      />
      <AnimatedParagraph id="finish-boost-message" wrapper="p" text={t(text)} />
      {finished ? (
        <div className="flex">
          <div
            className="button"
            onClick={() => shareViaTwitter(shareText, link)}
          >
            <Twitter id="finish-share-icon" />
          </div>
          <div
            className="button"
            onClick={() => shareViaFacebook(shareText, link)}
          >
            <Facebook id="finish-share-icon" />
          </div>
          <div
            className="button"
            onClick={() => shareViaWhatsApp(shareTextLong)}
          >
            <WhatsApp id="finish-share-icon" />
          </div>

          <div
            className="button"
            onClick={() => {
              copyToClipboard(link);
              alert(t("finish.linkCopied"));
            }}
          >
            <Link id="finish-share-icon" />
          </div>
        </div>
      ) : (
        <div className="flex align-center justify-center">
          <p id="form-disclaimer">
            {t("finish.disclaimer1")}
            <a
              href={`mailto:${t("finish.disclaimer2")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("finish.disclaimer2")}
            </a>
            {t("finish.disclaimer3")}
            <a
              href={t("finish.ppLink")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("finish.disclaimer4")}
            </a>
            {t("finish.disclaimer5")}
          </p>
        </div>
      )}
      {!finished && (
        <form>
          <div id="finish-form-container">
            <label>
              <input
                id="finish-email-input"
                placeholder={t("finish.email")}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <RhombusButton
              className="adjust-center"
              text={t("finish.collect-boost")}
              disabled={emailState === EmailState.SENDING}
              loading={emailState === EmailState.SENDING}
              onClick={() => {
                submitEmail(email);
              }}
            />
          </div>
        </form>
      )}
      {error && <p id="finish-error">{error}</p>}
    </div>
  );
}
