import React from "react";
import AudioContext from "../../../Context/AudioContext";
import { ReactComponent as AudioOff } from "../../../icons/audio_off.svg";
import { ReactComponent as AudioOn } from "../../../icons/audio_on.svg";
import "./AudioButton.css";

export type AudioButtonProps = {
  onToggle?: (on: boolean) => void;
};

function AudioButton({onToggle = undefined}: AudioButtonProps) {
  return (
    <AudioContext.Consumer>
      {({ audioEnabled, toggleAudio }) => (
        <div id="audio-toggle" className="button">
          <div onClick={() => {
            toggleAudio()
            onToggle?.(!audioEnabled)
          }}>
            {audioEnabled ? <AudioOn /> : <AudioOff />}
          </div>
        </div>
      )}
    </AudioContext.Consumer>
  );
}

export default AudioButton;
