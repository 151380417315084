import React from "react";
import { isMobile } from "../Mobile/MobileMediaDetector";
import { Game } from "../Model/Challenge";
import RotaxLogo from "../Rotax/RotaxLogo";
import { VideoState } from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import ChallengeDescription, {
  ChallengeDescriptionProps,
} from "./OverlayElements/ChallengeDescription";
import LapTime from "./OverlayElements/LapTime";
import OverviewElement from "./OverlayElements/OverviewElement";
import ShareElement from "./OverlayElements/ShareElement";
import "./SceneOverlay.css";

export enum SceneOverlayType {
  NORMAL,
  FINISH,
  HOME,
  TRACK,
  REDEEM,
}

export type SceneOverlayProps = {
  currentLapTime?: number;
  nextLapTime?: number;
  challengeDescription?: ChallengeDescriptionProps;
  type?: SceneOverlayType;
  game?: Game;
  videoState: VideoState;
  onNavigate: () => void;
};

function SceneOverlay({
  currentLapTime = 0,
  nextLapTime = 0,
  challengeDescription = undefined,
  type = SceneOverlayType.NORMAL,
  game = undefined,
  videoState,
  onNavigate,
}: SceneOverlayProps) {
  const mobile = isMobile();

  return (
    <div
      className={
        type === SceneOverlayType.HOME ||
        type === SceneOverlayType.TRACK ||
        (type === SceneOverlayType.FINISH && !mobile) ||
        type === SceneOverlayType.REDEEM
          ? "full-page overlay-home-track-columns"
          : "full-page overlay-default-columns"
      }
      id="scene-overlay"
    >
      <div className="justify-self-start">
        <div id="scene-overlay-rotax-logo">
          <RotaxLogo
            white={type !== undefined && type === SceneOverlayType.HOME}
          />
        </div>
      </div>

      <div className="justify-self-center">
        {type === SceneOverlayType.NORMAL ||
        (type === SceneOverlayType.FINISH && mobile) ? (
          <div id="scene-overlay-lap-time">
            <LapTime
              key={currentLapTime + nextLapTime}
              lapTime={currentLapTime}
              nextLapTime={nextLapTime}
            />
          </div>
        ) : (
          <div />
        )}
      </div>

      <div className="justify-self-end">
        <OverviewElement
          game={game}
          onNavigate={onNavigate}
          type={type}
          videoState={videoState}
        />
      </div>

      <div className="justify-self-start">
        <div></div>
      </div>

      <div className="justify-self-center"></div>

      <div className={"justify-self-end" + (mobile ? " zindex-10" : "")}>
        {type !== SceneOverlayType.HOME &&
        type !== SceneOverlayType.TRACK &&
        type !== SceneOverlayType.REDEEM ? (
          type === SceneOverlayType.FINISH ||
          challengeDescription === undefined ? (
            <ShareElement />
          ) : (
            <ChallengeDescription {...challengeDescription} />
            // <div />
          )
        ) : undefined}
      </div>
    </div>
  );
}

export default SceneOverlay;
