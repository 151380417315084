import Lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fadeOut } from "../../../Animations";
import useAudio from "../../../audio/Audio";
import breakPointPicker from "../../../lotties/050_brake_0_39.json";
import { ButtonProps } from "../../Buttons/ButtonProps";
import RedCircleButton from "../../Buttons/RedCircleButton/RedCircleButton";
import Dialog from "../../Dialog/Dialog";
import { TrackGameProps } from "../TrackGameProps";
import "./TrackBrake.css";

function TrackBreak({ onComplete, onInteraction }: TrackGameProps) {
  const { t, i18n } = useTranslation();
  const [score, setScore] = useState(-1);
  const [showDialog, setShowDialog] = useState(false);
  const brakeBest = useAudio(i18n.language + "/050_brake_good");
  const brakeLate = useAudio(i18n.language + "/050_brake_tooLate");
  const brakeEarly = useAudio(i18n.language + "/050_brake_tooEarly");

  /// this will in the future be used to determine if the user has breaked near the optimal frame
  /// final value depneds on final lottie
  const optimalFrame = 19;
  let animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementById("brake-point-picker") as Element,
        animationData: breakPointPicker,
        autoplay: true,
        renderer: "svg",
      });
    }
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  const stop = () => {
    if (score < 0) {
      onInteraction?.();
      animation.current?.pause();

      const currentFrame = animation.current?.currentFrame ?? 0;
      const scoreMultiplier = 5;
      // score e.g. abs(27/28) or abs(29/28) = 0.03 * 5 = 0.26seconds
      const score = Math.abs(currentFrame / optimalFrame - 1) * scoreMultiplier;
      setScore(score);

      setTimeout(() => {
        setShowDialog(true);
        if (score <= 0.5) {
          brakeBest?.play();
        } else if (currentFrame / optimalFrame - 1 < 0) {
          brakeEarly?.play();
        } else {
          brakeLate?.play();
        }
      }, 1000);
    }
  };

  const completeGame = () => {
    onComplete(score);
    setShowDialog(false);
  };

  const restartGame = () => {
    setScore(-1);
    setShowDialog(false);
    animation.current?.play();
  };

  const braked = score > 0;

  let retry: ButtonProps | undefined = undefined;
  let header = "games.dialog-best.header";
  let title = "games.dialog-best.title";
  let message = "brake.dialog-message";

  if (score > 1.0) {
    title = "games.dialog-bad.title";
    retry = {
      onClick: restartGame,
      text: t("brake.retry"),
    };
  } else if (score > 0.5) {
    title = "games.dialog-mediocre.title";
    retry = {
      onClick: restartGame,
      text: t("brake.retry"),
    };
  }

  return (
    <div id="track-brake" className="full-page">
      <div
        key="brake-point-picker"
        id="brake-point-picker"
        className="full-page full-size"
      ></div>

      {showDialog && (
        <Dialog
          header={t(header)}
          title={t(title)}
          message={t(message)}
          buttonProps={{
            onClick: completeGame,
            text: t("brake.ok"),
          }}
          retryButtonProps={retry}
        />
      )}
      <RedCircleButton
        className={braked ? "brake-button fadeOut" : "brake-button"}
        text={t("brake.red-circle")}
        onDown={stop}
      />
    </div>
  );
}

export default TrackBreak;
