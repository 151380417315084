import React, { ElementType, useEffect } from "react";
import anime from "animejs";
import "./AnimatedParagraph.css";

export type AnimatedParagraphProps = {
  text: string;
  delay?: number;
  className?: string;
  id?: string;
  wrapper: any;
  uniqueIdentifier?: string | number; // used if needed with same id on multiple pages
  initialDealy?: number;
};

function AnimatedParagraph({
  text,
  delay = undefined,
  id = undefined,
  className = undefined,
  uniqueIdentifier = undefined,
  wrapper,
  initialDealy = 0,
}: AnimatedParagraphProps) {
  const letterDelay = delay ?? 30;
  const spans = [];
  var key = 0;
  const spanId = "ap-letter" + (uniqueIdentifier ?? "");
  for (let char of text) {
    spans.push(
      <span id={spanId} key={char + key}>
        {char}
      </span>
    );
    key++;
  }

  useEffect(() => {
    anime.timeline({ loop: false }).add({
      targets: wrapper + " #" + spanId,
      scale: [4, 1],
      opacity: [0, 1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 500,
      delay: (el, i) => letterDelay * i + initialDealy,
    });
  }, []);

  const Wrapper = wrapper;

  return (
    <Wrapper key={text} id={id} className={className}>
      {spans}
    </Wrapper>
  );
}

export default AnimatedParagraph;
