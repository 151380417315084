import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as Arrow } from "../../../icons/arrow_left.svg";
import AudioButton from "../../Buttons/AudioButton/AudioButton";
import HomeButton from "../../Buttons/HomeButton/HomeButton";
import MobileDetector from "../../Mobile/MobileDetector/MobileDetector";
import { SceneOverlayType } from "../SceneOverlay";
import "./OverviewElement.css";
import Share from "./Share";
import trackProgress from "../../../lotties/track-progress_start_0_100_corner1_100_180_tunnel_180_405_brake_405_554_corner2_554_704_boost_704_785.json";
import Lottie, { AnimationItem, AnimationSegment } from "lottie-web";
import { Game } from "../../Model/Challenge";
import { VideoState } from "../../VideoLibrary/Video/VideoTransition/VideoTransition";

export type OverviewElementProps = {
  type: SceneOverlayType;
  game?: Game;
  videoState: VideoState;
  onNavigate?: () => void;
};

function OverviewElement({
  type,
  game = undefined,
  videoState,
  onNavigate = undefined,
}: OverviewElementProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const animation = useRef<AnimationItem | null>(null);

  function getSegments(): AnimationSegment {
    if (videoState === VideoState.Play) {
      switch (game) {
        case Game.START:
          return [0, 100];
        case Game.LINE1:
          return [100, 180];
        case Game.TUNNEL:
          return [180, 405];
        case Game.BREAK:
          return [405, 554];
        case Game.LINE2:
          return [554, 705];
        case Game.BOOST:
          return [705, 785];
      }
    } else if (videoState === VideoState.Ended) {
      switch (game) {
        case Game.START:
          return [0, 1];
        case Game.LINE1:
          return [100, 101];
        case Game.TUNNEL:
          return [180, 181];
        case Game.BREAK:
          return [405, 406];
        case Game.LINE2:
          return [554, 555];
        case Game.BOOST:
          return [705, 706];
      }
    } else {
      switch (game) {
        case Game.START:
          return [99, 100];
        case Game.LINE1:
          return [179, 180];
        case Game.TUNNEL:
          return [404, 405];
        case Game.BREAK:
          return [553, 554];
        case Game.LINE2:
          return [704, 705];
        case Game.BOOST:
          return [784, 785];
      }
    }
    return [0, 1];
  }

  useEffect(() => {
    if (animation.current === null) {
      animation.current = Lottie.loadAnimation({
        container: document.getElementsByClassName(
          "track-image-lottie" + game
        )[0] as Element,
        animationData: trackProgress,
        autoplay: true,
        renderer: "svg",
        initialSegment: getSegments(),
        loop: false,
      });
    } else {
      animation.current.playSegments(getSegments());
    }
  }, [videoState]);

  useEffect(() => {
    return () => {
      animation.current?.destroy();
      animation.current = null;
    };
  }, []);

  return (
    <div id="overview-element">
      <div id="top-container">
        <MobileDetector>
          {type === SceneOverlayType.NORMAL && game !== undefined && (
            <div id="track-image" className={"track-image-lottie" + game} />
          )}
        </MobileDetector>
        {type === SceneOverlayType.HOME && (
          <div id="track-info-header-container" className="flex align-center">
            <h1
              onClick={() => {
                history.push("/track-overview");
                onNavigate?.();
              }}
              id="track-info-header"
            >
              {t("overlay.track-info")}
            </h1>
          </div>
        )}
        {type === SceneOverlayType.TRACK && (
          <div
            id="back-container"
            className="flex align-center button"
            onClick={() => {
              history.push("/home");
              onNavigate?.();
            }}
          >
            <Arrow id="back-image" stroke="#FFFFFF" />
            <h1 id="back-text">{t("overlay.back")}</h1>
          </div>
        )}
        <div id="overview-right-element-container">
          {type !== SceneOverlayType.HOME &&
            type !== SceneOverlayType.TRACK && (
              <HomeButton onNavigate={onNavigate} />
            )}
          {(type === SceneOverlayType.HOME ||
            type === SceneOverlayType.TRACK) && (
            <Share
              title="Rotax MAX Dome"
              text={t("overlay.share.check-out")}
              link="https://racex.rotaxmaxdome.com/linz"
            />
          )}
          <div id="overview-audio-button">
            <AudioButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverviewElement;
