// Copyright Butleroy GmbH 2020

import React from "react";
import UAParser from "ua-parser-js";
import {
  getVideoFileExtension,
  getVideoType,
  SupportedVideoFormat,
} from "../../FileFormats/SupportedFormat";
import { isMobile, uaParserInstance } from "../../Mobile/MobileMediaDetector";

export type VideoProps = {
  loop?: boolean;
  src: any;
  playing?: boolean;
  onStart?: () => void;
  onEnded?: () => void;
  onDuration?: (duration: number) => void;
  className?: string;
  muted: boolean;
  width?: string;
  height?: string;
};

const MultiSourceVideo = React.forwardRef<HTMLVideoElement, VideoProps>(
  (
    {
      loop = false,
      src,
      playing = false,
      onStart,
      onEnded,
      onDuration,
      className = undefined,
      muted = true,
      width = undefined,
      height = undefined,
    }: VideoProps,
    ref
  ) => {
    const sources = [];
    const browser = uaParserInstance.getBrowser();
    const device = uaParserInstance.getDevice();
    const videoFormats =
      browser.name === "Edge" ||
      device.vendor?.toLowerCase().includes("xiaomi") ||
      device.vendor?.toLowerCase().includes("poco") ||
      device.vendor === "OnePlus"
        ? [SupportedVideoFormat.H264]
        : [
            SupportedVideoFormat.AV1, // working on chrome desktop
            SupportedVideoFormat.HEVC, // working on safari
            SupportedVideoFormat.WEBM, // working on chrome desktop
            SupportedVideoFormat.H264, // works on all browsers
          ];
    const mobile = isMobile();

    // add sources for all supported formats
    for (let format of videoFormats) {
      const fileExtension = getVideoFileExtension(format);
      const type = getVideoType(format);

      if (mobile) {
        try {
          const video = require("../../../videos/mobile/" +
            src +
            fileExtension).default;
          sources.push(
            <source
              key={type + format + "mobile"}
              media="(max-width: 992px)"
              type={type}
              src={video + "#t=0.001"}
            />
          );
        } catch {
          console.log("Mobile file not found " + src);
        }
      }

      try {
        const video = require("../../../videos/" + src + fileExtension).default;
        /// the #t=0.001 is a hack for safari mobile
        sources.push(
          <source key={format + type} type={type} src={video + "#t=0.001"} />
        );
      } catch {
        console.log("File not found " + src);
      }
    }

    return (
      <video
        ref={ref}
        className={className}
        controls={false}
        playsInline={true}
        autoPlay={playing}
        loop={loop}
        preload="auto"
        muted={muted}
        width={width ?? "100%"}
        height={height ?? "100%"}
        crossOrigin="anonymous"
        onPlay={() => {
          onStart?.();
        }}
        onEnded={() => {
          onEnded?.();
        }}
        onLoadedMetadata={(e: any) => {
          onDuration?.(e.target.duration * 1000);
        }}
      >
        {sources}
      </video>
    );
  }
);

export default MultiSourceVideo;
