import Lottie, { AnimationItem } from "lottie-web";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fadeOut, fadeTo } from "../../../Animations";
import useAudio from "../../../audio/Audio";
import corner1Interaction from "../../../lotties/030_corner1_interaction_0_49.json";
import corner1Solution from "../../../lotties/030_corner1_solution_0_49.json";
import corner2Interaction from "../../../lotties/060_corner2_interaction_0_49.json";
import corner2Solution from "../../../lotties/060_corner2_solution_0_49.json";
import RedCircleButton from "../../Buttons/RedCircleButton/RedCircleButton";
import RedCircleButtonArrows from "../../Buttons/RedCircleButton/RedCircleButtonArrows";
import RhombusButton from "../../Buttons/RhombusButton";
import Dialog from "../../Dialog/Dialog";
import { isMobile } from "../../Mobile/MobileMediaDetector";
import { Game } from "../../Model/Challenge";
import { TrackGameProps } from "../TrackGameProps";
import "./TrackCorner.css";

function TrackCorner({ game, onComplete, onInteraction }: TrackGameProps) {
  const { t, i18n } = useTranslation();

  const [trackMove, setTrackMove] = useState(false);
  const [canTrack, setCanTrack] = useState(true);
  const [trackedX, setTrackedX] = useState(game === Game.LINE1 ? 35 : 53);
  const [frame, setFrame] = useState(0);
  const [score, setScore] = useState(-1);
  const cornerBest = useAudio(i18n.language + "/030_corner_good");
  const cornerMediocre = useAudio(i18n.language + "/030_corner_veryclose");
  const cornerBad = useAudio(i18n.language + "/030_corner_bad");

  /// final value depneds on final lottie
  const optimalFrame = game === Game.LINE1 ? 18 : 31;
  const interactionAnimation = useRef<AnimationItem | null>(null);
  const solutionAnimation = useRef<AnimationItem | null>(null);

  const interaction =
    game === Game.LINE1 ? corner1Interaction : corner2Interaction;
  const solution = game === Game.LINE1 ? corner1Solution : corner2Solution;

  useEffect(() => {
    if (solutionAnimation.current === null) {
      solutionAnimation.current = Lottie.loadAnimation({
        container: document.getElementById("racing-line-solution") as Element,
        animationData: solution,
        autoplay: false,
        loop: false,
        renderer: "svg",
      });
    }

    if (interactionAnimation.current === null) {
      interactionAnimation.current = Lottie.loadAnimation({
        container: document.getElementById("racing-line-picker") as Element,
        animationData: interaction,
        autoplay: false,
        renderer: "svg",
      });
    }
    //Lottie.setQuality("low"); // possible performance improvement
    interactionAnimation.current?.goToAndStop(frame, true);
  }, [frame]);

  useEffect(() => {
    return () => {
      interactionAnimation.current?.destroy();
      interactionAnimation.current = null;
      solutionAnimation.current?.destroy();
      solutionAnimation.current = null;
    };
  }, []);

  const track = useCallback(
    (x: number) => {
      if (trackMove && canTrack) {
        const trackPercent = (x / window.innerWidth) * 100;
        setTrackedX(trackPercent);

        // reduce percentage calculated to the middle half of the screen
        const quarterWidth = window.innerWidth / 4;
        const adaptedX = x - quarterWidth;
        const percentX = adaptedX / (window.innerWidth / 2);
        const lottieFrames = interactionAnimation.current?.totalFrames ?? 49;
        const calculatedFrame = Math.round(lottieFrames * percentX);
        const newFrame = Math.max(
          0,
          Math.min(calculatedFrame, lottieFrames - 1)
        );

        if (frame != newFrame) {
          setFrame(newFrame);
        }
      }
    },
    [trackMove, canTrack]
  );

  const confirmInteraction = () => {
    setCanTrack(false);
    onInteraction?.();

    solutionAnimation.current?.play();
    const scoreMultiplier = 3;
    const score = Math.abs(frame / optimalFrame - 1) * scoreMultiplier;
    // score e.g. abs(16/13) or abs(10/13) = 0.23 * 2 = 0.46seconds
    fadeTo("#racing-line-picker", 200, 0.5);
    fadeOut(".red-circle-track-corner");
    fadeOut("#select-racing-line");

    setTimeout(() => {
      if (score <= 1.0) {
        cornerBest?.play();
      } else if (score > 1.0 && score <= 2.0) {
        cornerMediocre?.play();
      } else {
        cornerBad?.play();
      }
      setScore(score);
    }, 1500);
  };

  const completeGame = () => {
    onComplete(score);
    setScore(-1);
  };

  let header = "games.dialog-best.header";
  let title = "games.dialog-best.title";
  let message = "corner.dialog-message";

  if (score > 2.0) {
    title = "games.dialog-bad.title";
  } else if (score > 1.0) {
    title = "games.dialog-mediocre.title";
  }

  const mobile = isMobile();

  return (
    <div
      id="track-corner-game"
      className="full-page"
      onMouseDown={() => setTrackMove(true)}
      onMouseUp={() => setTrackMove(false)}
      onMouseMove={(e) => track(e.pageX)}
      onTouchMove={(e) => track(e.changedTouches[0].pageX)}
      onTouchStart={() => setTrackMove(true)}
      onTouchEnd={() => setTrackMove(false)}
    >
      <div
        key="racing-line-solution"
        id="racing-line-solution"
        className="full-page full-size"
      ></div>
      <div
        key="racing-line-picker"
        id="racing-line-picker"
        className="full-page full-size"
      ></div>
      {score >= 0 && (
        <Dialog
          header={t(header)}
          title={t(title)}
          message={t(message)}
          buttonProps={{
            onClick: completeGame,
            text: "OK",
          }}
        />
      )}
      {canTrack && (
        <div
          className={
            game === Game.LINE1
              ? "red-circle-track-corner1"
              : "red-circle-track-corner2"
          }
          // RedCircleButton size is 25vmin for desktop and 32vmin for mobile
          style={{
            marginLeft: mobile
              ? "calc(" + trackedX + "% - 22.5vmin)"
              : "calc(" + trackedX + "% - 17vmin)",
          }}
        >
          <RedCircleButtonArrows
            buttonProps={{ text: t("corner.red-circle") }}
          />
        </div>
      )}
      <div
        id="select-racing-line"
        onTouchMove={(e) => e.stopPropagation()}
        onMouseMove={(e) => e.stopPropagation()}
      >
        <RhombusButton text={t("corner.button")} onClick={confirmInteraction} />
      </div>
    </div>
  );
}

export default TrackCorner;
