import React, { ReactNode } from "react";
import UAParser from "ua-parser-js";
import { uaParserInstance } from "../MobileMediaDetector";

export type MobileDetectorProps = {
  mobileFallback?: any;
  children?: ReactNode;
};

/// Only renders children if the device is NOT mobile. Otherwise the mobileFallback component is rendered or an empty div.
function MobileDetector({
  mobileFallback = <div />,
  children = <div />,
}: MobileDetectorProps) {
  const device = uaParserInstance.getDevice();
  return device.type === UAParser.DEVICE.MOBILE ? mobileFallback : children;
}

export default MobileDetector;
