export const environment = {
  production: false,
  baseUrl: "http://localhost:8080",
  googleAnalyticsId: "",
};

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  environment.baseUrl = "https://rmdapi.theshipyard.dev";
  environment.googleAnalyticsId = "G-4FYJN0N5JN";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  environment.production = true;
  environment.baseUrl = "https://api.rotaxmaxdome.com";
  environment.googleAnalyticsId = "G-J6ZY0STCT3";
}
