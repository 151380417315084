// Copyright Butleroy GmbH 2020

import React, { ReactElement, useRef } from "react";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./TransitionRoute.css";

interface TransitionProps extends RouteComponentProps<any> {
  path: string;
  children: ReactElement;
}

const timeout = 500;

function TransitionRoute({ path = "", children }: TransitionProps) {
  const nodeRef = useRef(null);

  return (
    <Route key={path} path={path} exact>
      {({ match }: any) => (
        <CSSTransition
          nodeRef={nodeRef}
          key={path}
          in={match != null}
          timeout={timeout}
          classNames="page"
          unmountOnExit={true}
        >
          <div className="page" ref={nodeRef}>
            {React.cloneElement(children, { match: match })}
          </div>
        </CSSTransition>
      )}
    </Route>
  );
}

export default withRouter(TransitionRoute);
