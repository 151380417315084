import React from "react";
import logo from "../../icons/rotax_logo.svg";
import logoWhite from "../../icons/rotax_logo_white.svg";
import { isMobile } from "../Mobile/MobileMediaDetector";

export type LogoProps = {
  white: boolean;
};

export default function RotaxLogo({ white }: LogoProps) {

  const mobile = isMobile();

  return (
    <div id="rotax-logo-container" className="pointer-events-all">
      <a
        href="https://www.rotaxmaxdome.com/linz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {!white && <img src={logo} style={mobile ? {width: "80px"} : {width: "130px"}} />}
        {white && <img src={logoWhite} style={mobile ? {width: "80px"} : {width: "130px"}} />}
      </a>
    </div>
  );
}
