import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "./ModalView.css";

export type ModalViewProps = {
  showing: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

function ModalView({
  showing,
  className = undefined,
  children,
  onClick = undefined,
}: ModalViewProps) {
  return showing ? (
    ReactDOM.createPortal(
      <div id="modal-view" className={className} onClick={onClick}>
        {children}
      </div>,
      document.body
    )
  ) : (
    <div></div>
  );
}

export default ModalView;
