import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { fadeIn, fadeOut } from "../../Animations";
import AudioContext from "../../Context/AudioContext";
import AnimatedParagraph from "../AnimatedParagraph/AnimatedParagraph";
import AudioButton from "../Buttons/AudioButton/AudioButton";
import RhombusButton from "../Buttons/RhombusButton";
import MobileDetector from "../Mobile/MobileDetector/MobileDetector";
import { isTablet } from "../Mobile/MobileMediaDetector";
import { challenges, Game } from "../Model/Challenge";
import LapTimeCalculator from "../Model/LapTimeCalculator";
import MultiSourceImage from "../MultiSourceImage/MultiSourceImage";
import PeekThrough from "../PeekThrough/PeekThrough";
import SceneOverlay, { SceneOverlayType } from "../SceneOverlay/SceneOverlay";
import { VideoProps } from "../VideoLibrary/Video/MultiSourceVideo";
import VideoTransition, {
  VideoState,
} from "../VideoLibrary/Video/VideoTransition/VideoTransition";
import { viewPortConstants } from "../VideoLibrary/Video/ViewPortHandler/ViewPortHandler";
import "./Home.css";

export type HomeProps = {
  lapTimeCalculator: LapTimeCalculator;
};

function Home({ lapTimeCalculator }: HomeProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoState, setVideoState] = useState(VideoState.Ended);
  const audioContext = useContext(AudioContext);
  const [buttonClicked, setButtonClicked] = useState(false);

  const imageSrc = i18n.language + "/010_home.first";
  const videoProps: VideoProps = {
    src: i18n.language + "/010_home",
    muted: false,
    className: "",
    onEnded: () => {
      setVideoState(VideoState.Transition);
      history.push("/start");
    },
  };

  useEffect(() => {
    document.body.style.background = "var(--color-rotax-red)";
    setTimeout(() => {
      fadeIn("#scene-overlay");
    }, 500);
    return () => {
      document.body.style.background = "";
    };
  }, []);

  const fadeOutStartGames = () => {
    fadeOut("#home-content");
    fadeOut("#peek-through", 1);
    fadeOut("#cover-image", 1);
    fadeOut("#scene-overlay");
  };

  const startNewRace = () => {
    if (!buttonClicked) {
      if (videoRef.current) {
        // hack needed to get sound on video on mobile
        videoRef.current.muted = !audioContext.audioEnabled;
        videoRef.current.play();
      }
      lapTimeCalculator.reset();
      setVideoState(VideoState.Play);
      fadeOutStartGames();
      setTimeout(() => {
        fadeIn("#skip-home");
      }, 500);
    }
  };

  return (
    <div>
      <div id="home-transition">
        <VideoTransition
          ref={videoRef}
          videoState={videoState}
          image={imageSrc}
          videoProps={videoProps}
        >
          <MobileDetector>
            {!isTablet() && (
              <PeekThrough
                onClicked={() => {
                  if (!buttonClicked) {
                    setButtonClicked(true);
                    fadeOut("#home-content");
                    fadeOut("#cover-image");
                  }
                }}
                onShowTrack={() => {
                  history.push("/track-overview");
                }}
                className="full-page"
                backgroundSrc="trackinfo/trackinfo_overview"
                peekSrc={imageSrc}
                viewPortConstants={viewPortConstants}
              />
            )}
          </MobileDetector>
          <MultiSourceImage
            id="cover-image"
            className="full-page"
            src={"testimonial/" + i18n.language + "/000_wurz"}
          />
        </VideoTransition>
      </div>

      <SceneOverlay
        videoState={videoState}
        key="home-overlay"
        onNavigate={() => {
          fadeOut("#home-transition");
          fadeOut("#home-content");
          fadeOut("#scene-overlay");
        }}
        type={SceneOverlayType.HOME}
      />
      <div id="home-content" className={"full-page flex align-center"}>
        <div className="flex-65">
          <AnimatedParagraph
            wrapper="h4"
            text={t("home.headline-top")}
            initialDealy={500}
          />
          <AnimatedParagraph
            wrapper="h1"
            text={t("home.headline-sub")}
            initialDealy={500}
          />
          <AnimatedParagraph
            wrapper="p"
            text={t("home.boostsAll")}
            initialDealy={500}
          />
          {lapTimeCalculator.currentGame === undefined ||
          lapTimeCalculator.currentGame === Game.BOOST ? (
            <div className="flex align-center justify-center">
              <div className="pointer-events-all padding-16">
                <RhombusButton onClick={startNewRace} text={t("home.start")} />
              </div>
            </div>
          ) : (
            <div className="flex align-center justify-center pointer-events-all padding-16">
              <RhombusButton onClick={startNewRace} text={t("home.restart")} />
              <RhombusButton
                className="plain"
                onClick={() => {
                  if (!buttonClicked) {
                    setVideoState(VideoState.Transition);
                    const path = challenges.find(
                      (element) =>
                        element.gameType === lapTimeCalculator.currentGame
                    )?.nextPath;
                    if (path) {
                      fadeOut("#home-transition");
                      fadeOut("#home-content");
                      history.push("/" + path);
                    }
                  }
                }}
                text={t("home.continue")}
              />
            </div>
          )}
        </div>
        <div id="home-testimonial-name">
          <p id="home-f1">{t("home.formula-driver")}</p>
          <h3 id="home-driver-name">{t("home.driver-name")}</h3>
        </div>
      </div>
      {videoState === VideoState.Play && (
        <div id="skip-home" className="button opacity-0">
          <h3
            onClick={() => {
              setVideoState(VideoState.Transition);
              history.push("/start");
            }}
          >
            {t("home.skip")}
          </h3>
          <div id="audio-button-home">
            <AudioButton
              onToggle={(on) => {
                if (videoRef.current) {
                  videoRef.current.muted = !on;
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
